import { createSelector } from "@reduxjs/toolkit";
import { RawCellContent } from "hyperformula";
import { AsRowsAccType, GridRowData, colNumberToExcelCol } from "src/classes/RenderedDoc";
import { spreadSelector } from "src/redux/selectors/app.selectors";
import { GroupName, PropertyGroups, TabName } from "src/redux/reducers/types";
import { GridState } from "src/classes/GridState";
import { mainCandidatesSelector } from "./ocr.selector";
import { RentRollTableData } from "@interfold-ai/shared/models/Property";

export const gridStateToGridRowData = (gridState: GridState) => {
  return Object.entries(gridState).map(([key, value]) => {
    return {
      id: key,
      ...value.rowDataArray.reduce((acc: AsRowsAccType, cur: RawCellContent, index: number) => {
        const columnName = colNumberToExcelCol(index + 1); // Convert index to column name
        acc[columnName] = {
          value: cur,
          style: value.rowStyle,
          metadata: value.rowMetadata,
          type: index === 0 ? "text" : value.rowDataType,
          isManagedByApp: value.isManagedByApp,
          isEditable: value.isEditable,
        };
        return acc;
      }, {} as AsRowsAccType),
    };
  }) as GridRowData[];
};

export const tabNamesSelector = createSelector(spreadSelector, (spread) => {
  const tabNames = Object.keys(spread.gridStates || {}) as TabName[];
  const groups = spread.tabGroups || [];

  const sortedTabNames = tabNames.sort((a, b) => {
    const groupA = groups.find((grp) => grp.tabName === a)?.group ?? "Legacy";
    const groupB = groups.find((grp) => grp.tabName === b)?.group ?? "Legacy";
    return groupA.localeCompare(groupB);
  });
  return sortedTabNames;
});

export const gridRowDataSelector = createSelector(
  spreadSelector,
  tabNamesSelector,
  (spread, orderedTabNames) => {
    if (!spread.gridStates) {
      return null;
    }
    const gridRowDatas = orderedTabNames.reduce(
      (acc, tabName) => {
        if (spread.gridStates && spread.gridStates[tabName]) {
          acc[tabName] = gridStateToGridRowData(spread.gridStates[tabName]);
        }
        return acc;
      },
      {} as Record<TabName, GridRowData[]>,
    );
    return gridRowDatas;
  },
);

export const gridStatesSelector = createSelector(spreadSelector, (spread) => {
  return spread.gridStates;
});

export const gridOptionsSelector = createSelector(spreadSelector, (spread) => {
  return spread.gridOptions;
});

export const tabGroupsSelector = createSelector(spreadSelector, (spread) => {
  return spread.tabGroups;
});

export const currentSpreadIdSelector = createSelector(spreadSelector, (spread) => {
  return spread.currentSpreadId;
});

export const columnsDefsSelector = createSelector(spreadSelector, (spread) => {
  return spread.columnsDefs;
});

export const formulaBarSelector = createSelector(spreadSelector, (spread) => {
  return spread.formulaBar;
});

export const sheetsSelector = createSelector(spreadSelector, (spread) => {
  return { rawSheets: spread.rawSheets, calculatedSheets: spread.calculatedSheets };
});

export const subjectAssetsSelector = createSelector(spreadSelector, (spread) => {
  return spread.subjectAssets as RentRollTableData[] | null;
});

export const rawSheetsSelector = createSelector(spreadSelector, (spread) => {
  return spread.rawSheets;
});

export const calculatedSheetsSelector = createSelector(spreadSelector, (spread) => {
  return spread.calculatedSheets;
});

export const spreadUserStateSelector = createSelector(spreadSelector, (spread) => {
  return spread.userState;
});

export const analysisTypeSelector = createSelector(spreadSelector, (spread) => {
  return spread.analysisType;
});

export const hoverInfoSelector = createSelector(spreadSelector, (spread) => {
  return spread.hoverInfos || {};
});

export const documentUploadIdsSelector = createSelector(spreadSelector, (spread) => {
  return spread.documentUploadIds || {};
});

export const confidenceSelector = createSelector(spreadSelector, (spread) => {
  return spread.confidences;
});

export const selectedAssetsSelector = createSelector(
  spreadSelector,
  mainCandidatesSelector,
  (spread, candidates) => {
    if (!spread.selectedAssets || spread.selectedAssets.length === 0) {
      return candidates;
    }
    return spread.selectedAssets.filter((asset) => candidates.includes(asset));
  },
);

export const jointlyOwnedAssetsSelector = createSelector(
  spreadSelector,
  selectedAssetsSelector,
  (spread, selectedAssets) =>
    spread.jointlyOwnedAssets
      .filter((ja) => ja.assets.some((asset) => selectedAssets.includes(asset)))
      .filter((ja) => ja.assets.length > 0),
);

export const colSpansSelector = createSelector(spreadSelector, (spread) => {
  return spread.colSpans;
});

export const selectedWithRespectToJointlyOwnedAssetsSelector = createSelector(
  selectedAssetsSelector,
  jointlyOwnedAssetsSelector,
  (selectedAssets, jointlyOwnedAssets) => [
    ...selectedAssets.filter(
      (asset) => !jointlyOwnedAssets.some((ja) => ja.assets.includes(asset)),
    ),
    ...jointlyOwnedAssets.map((ja) => ja.displayName),
  ],
);

export const INDIVIDUAL_PROPERTIES = "Individual Properties" as GroupName;
export const propertyGroupsSelector = createSelector(
  spreadSelector,
  selectedWithRespectToJointlyOwnedAssetsSelector,
  jointlyOwnedAssetsSelector,
  (spread, selectedAssets, jointlyOwnedAssets) => {
    const jointlyOwnedDisplaynames = jointlyOwnedAssets.map((ja) => ja.displayName);
    const existingProperties = new Set(
      Object.values(spread.propertyGroups).flatMap((group) => group.properties),
    );

    const missingProperties = selectedAssets.filter(
      (property) => !existingProperties.has(property),
    );

    const individualPropertiesGroup = {
      properties: [
        ...(spread.propertyGroups[INDIVIDUAL_PROPERTIES]?.properties || []).filter(
          (property) =>
            selectedAssets.includes(property) || jointlyOwnedDisplaynames.includes(property),
        ),
        ...missingProperties,
      ],
    };

    const otherGroups = Object.entries(spread.propertyGroups)
      .filter(([groupName]) => groupName !== INDIVIDUAL_PROPERTIES)
      .reduce((acc, [groupName, group], index) => {
        acc[groupName as GroupName] = {
          properties: [
            ...group.properties.filter(
              (property) =>
                selectedAssets.includes(property) || jointlyOwnedDisplaynames.includes(property),
            ),
          ],
          index: group.index ?? index,
        };
        return acc;
      }, {} as PropertyGroups);

    const newPropertyGroups = {
      ...otherGroups,
      [INDIVIDUAL_PROPERTIES]: individualPropertiesGroup,
    };

    return newPropertyGroups;
  },
);
