import { AutoRendered } from "src/classes/AutoRendered";
import { HoverLabel } from "src/classes/RenderedDoc";
import { RenderableBase } from "@interfold-ai/shared/models/render/common";
export abstract class AutoRenderedWithLineNumberHovers<
  T extends RenderableBase,
> extends AutoRendered<T> {
  public abstract labelToLineNumber: Record<string, number>;

  setHoverLabels(colsToSkip: number[] = [0]): void {
    const labelsToSet = Object.entries(this.labelToLineNumber);
    labelsToSet.forEach(([label, lineNumber]) => {
      if (!label) {
        return;
      }

      this.setHoverInfoForLabel(
        label,
        HoverLabel.from(`Schedule E Line ${lineNumber}`),
        colsToSkip,
      );
    });
  }

  constructor(underlying: T, columnId: string = "B", rowStart: number = 0) {
    super(underlying, columnId, rowStart);
  }
}
