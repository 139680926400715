import type { TaskStatus } from "@interfold-ai/shared/models/BackgroundTasks";
import type { AnalysisType } from "@interfold-ai/shared/models/SpreadsTabConstants";
import { createAsyncThunk, createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import { AppState } from "../reducers";
import { EntityHydrated } from "src/models/entity";
import { set } from "lodash";

export enum LoanSection {
  OVERVIEW = "overview",
  BORROWER = "borrower",
  GUARANTORS = "guarantors",
  CONGRATULATIONS = "congratulations",
}

export interface BorrowerState {
  isLoading: boolean;
  loanId?: number;
  activeSectionIndex: number;
  activeEntity: EntityHydrated | null;
  activeStep: number;
  showCongratulationsPage: boolean;
}

export const initialBorrowerState: BorrowerState = {
  isLoading: false,
  activeSectionIndex: 0,
  activeEntity: null,
  activeStep: 0,
  showCongratulationsPage: false,
};

export const selectBorrowerState = (state: AppState) => state.borrowerState;

export const borrowerStateSlice: Slice<BorrowerState> = createSlice({
  name: "borrowerState",
  initialState: initialBorrowerState,
  reducers: {
    resetBorrowerState: (state) => {
      state = initialBorrowerState;
    },
    setLoanId: (state, action: PayloadAction<{ loanId: number }>) => {
      state.loanId = action.payload.loanId;
    },
    setActiveSection: (state, action: PayloadAction<{ activeSectionIndex: number }>) => {
      state.activeSectionIndex = action.payload.activeSectionIndex;
      state.activeStep = 0;
    },
    setActiveEntity: (state, action: PayloadAction<{ activeEntity: EntityHydrated }>) => {
      state.activeEntity = action.payload.activeEntity;
    },
    setActiveStep: (state, action: PayloadAction<{ activeStep: number }>) => {
      state.activeStep = action.payload.activeStep;
    },
    setShowCongratulationsPage: (
      state,
      action: PayloadAction<{ showCongratulationsPage: boolean }>,
    ) => {
      state.showCongratulationsPage = action.payload.showCongratulationsPage;
    },
  },
});

export default borrowerStateSlice.reducer;
