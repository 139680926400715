import { Form1040 } from "@interfold-ai/shared/models/tax/Form1040";
import { Form1065 } from "@interfold-ai/shared/models/tax/Form1065";
import { Form1120S } from "@interfold-ai/shared/models/tax/Form1120s";
import { Form1120 } from "@interfold-ai/shared/models/tax/Form1120";
import { TaxFormData, TaxFormYear } from "@interfold-ai/shared/models/tax/common";
import { RenderedWorkflow } from "src/classes/RenderedDocuments/RenderedWorkflow";
import type { ColDef } from "ag-grid-community";
import { BoundingBoxContext, GridState } from "src/classes/GridState";
import { ExtractableDocumentType } from "@interfold-ai/shared/enums/ExtractableDocumentType";
import { PersonalCashFlowData } from "@interfold-ai/shared/models/PersonalCashFlow";
import { Form1120sIncomeStatementGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sIncomeStatementGrouped";
import { ScheduleK1 } from "@interfold-ai/shared/models/tax/schedules/ScheduleK1";
import { ScheduleK1Rendered } from "src/classes/RenderedDocuments/ScheduleK1Rendered";
import { TabName } from "src/redux/reducers/types";
import { Corporation, Person } from "@interfold-ai/shared/models/tax/common";
import { ScheduleCBreakoutRendered } from "src/classes/RenderedDocuments/ScheduleCBreakoutRendered";
import { ScheduleEBreakoutRendered } from "src/classes/RenderedDocuments/ScheduleEBreakoutRendered";
import { capitalizeFirstLetterOfEachWord } from "@interfold-ai/shared/extractors/helpers";
import { Form1120sBalanceSheetGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sBalanceSheetGrouped";
import { Form1120sCashFlowGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sCashFlowGrouped";
import { Form1120sRatiosGrouped } from "src/classes/RenderedDocuments/Form1120s/Form1120sRatiosGrouped";
import { Form1065BalanceSheetGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065BalanceSheetGrouped";
import { Form1065CashFlowGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065CashFlowGrouped";
import { Form1065IncomeStatementGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065IncomeStatementGrouped";
import { Form1065RatiosGrouped } from "src/classes/RenderedDocuments/Form1065/Form1065RatiosGrouped";
import { HoverInfo, RenderedDocOptions } from "src/classes/RenderedDoc";
import { Form8825BreakoutRendered } from "src/classes/RenderedDocuments/Form8825BreakoutRendered";
import {
  Form1040Grouped,
  Form1040WithTabNames,
} from "src/classes/RenderedDocuments/Form1040/Form1040Grouped";
import {
  buildGlobalCashFlow,
  Form1040RollUpAndTabName,
  Form1065CashFlowAndTabName,
  Form1120sCashFlowAndTabName,
} from "src/classes/RenderedDocuments/GlobalCashFlow/GlobalCashFlow";
import { ContextedBy } from "@interfold-ai/shared/models/extract/common";
import { ExtractOutput } from "@interfold-ai/shared/models/extract/common";
import { RenderableWithConfidence } from "./TaxFormWithConfidence";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { RenderableBase } from "@interfold-ai/shared/models/render/common";
import { sanitizeTabName } from "src/classes/RenderedDocuments/utils";
import {
  FinancialStatements,
  FinancialStatementType,
} from "@interfold-ai/shared/models/FinancialStatement";
import { FinancialStatementItemsRenderedDoc } from "src/classes/RenderedDocuments/FinancialStatementItemsRenderedDoc";
import {
  TabGroup,
  GeneralSpreadsTabTypeEnum,
  GeneralSpreadsTabGroupEnum,
} from "src/classes/RenderedDocuments/helpers";
import {
  buildCashFlowFromFinancialsTab,
  adjustInterestValuesForCashFlow,
  cashFlowCandidateRowsFromItems,
} from "src/classes/RenderedDocuments/financial-statement-utils";
import { spreadConfig } from "@interfold-ai/shared/spreads-config";
import { SupportedLenderId } from "@interfold-ai/shared/models/SpreadsConfig";
import { compareNames } from "src/utils/compareNames";
import { AppraisalReport } from "@interfold-ai/shared/models/appraisal";
import { AppraisalReportRenderedDoc } from "../AppraisalReportRenderedDoc";
import { buildScheduleFBreakoutRendered } from "../ScheduleFBreakoutRendered";
import { ScheduleF } from "@interfold-ai/shared/models/tax/schedules/ScheduleF";

export type GeneralSpreadsData = {
  [key: TabName]: {
    output: TaxFormData;
    source: ExtractableDocumentType;
    geometry?: ContextedBy<TaxFormData, BoundingBoxContext>;
    confidence?: ContextedBy<TaxFormData, number>;
    documentUploadId?: number;
  };
};

export class RenderedGeneralSpreads extends RenderedWorkflow {
  colDefs: Map<TabName, ColDef<any, any>[]> = new Map();
  gridStates: Map<TabName, GridState> = new Map();
  gridOptions: Map<TabName, RenderedDocOptions> = new Map();
  confidences: Map<TabName, RawConfidenceContent[][]> = new Map();
  tabGroups: TabGroup[] = [];
  hoverInfoData: Map<TabName, HoverInfo[][]> = new Map();
  documentUploadIds: Map<TabName, (number | null)[][]> = new Map();
  form1040s: RenderableWithConfidence<Form1040>[] = [];
  form1065s: Form1065[] = [];
  form1120Ss: RenderableWithConfidence<Form1120S>[] = [];
  form1120: Form1120[] = [];
  formk1: ScheduleK1[] = [];
  financialStatements: FinancialStatements[] = [];
  appraisalReports: AppraisalReport[] = [];
  legacyPersonalData: PersonalCashFlowData[] = [];
  lenderId: number | null = null;
  get tabs(): TabName[] {
    return Array.from(this.gridStates.keys());
  }

  constructor(public generalSpreads: GeneralSpreadsData) {
    super();
    Object.values(generalSpreads).forEach((extract) => this.placeForm(extract as ExtractOutput));
    const lenderId = Object.values(generalSpreads).map((value) => value.output.lenderId);
    if (lenderId.length > 0) {
      this.lenderId = lenderId[0];
    }

    const form1040Rollups = this.process1040();
    const form1065CashFlows = this.process1065();
    const form1120sCashFlows = this.process1120s();

    if (
      form1040Rollups.length > 0 ||
      form1065CashFlows.length > 0 ||
      form1120sCashFlows.length > 0
    ) {
      this.processGlobalCashFlow(form1040Rollups, form1065CashFlows, form1120sCashFlows);
    }

    this.processScheduleK1();
    this.process8825();
    this.processFinancialStatements();
    this.processAppraisalReports();
  }

  private placeForm(extract: ExtractOutput) {
    const { output, source, confidence, geometry } = extract;

    if (
      source === ExtractableDocumentType.FINANCIALS ||
      source === ExtractableDocumentType.INCOME_STATEMENT ||
      source === ExtractableDocumentType.BALANCE_SHEET
    ) {
      this.financialStatements.push(output as FinancialStatements);
      return;
    }
    if (source === ExtractableDocumentType.APPRAISAL_REPORT) {
      this.appraisalReports.push(output as AppraisalReport);
      return;
    }
    if (source === ExtractableDocumentType.SCHEDULE_K1 && Array.isArray(output)) {
      const k1s = output as ScheduleK1[];
      this.formk1.push(...k1s);
    }

    const taxFormData = output as TaxFormData;

    switch (taxFormData.form) {
      case GeneralSpreadsTabGroupEnum.Form1040: {
        const renderable = taxFormData as Form1040;
        const res = {
          renderable,
          confidence: confidence as ContextedBy<Form1040, number>,
        };
        this.form1040s.push(res);
        if (renderable.relatedK1s) {
          this.formk1.push(...renderable.relatedK1s);
        }
        return;
      }
      case GeneralSpreadsTabGroupEnum.Form1065:
        const form1065 = taxFormData as Form1065;
        this.form1065s.push(form1065);
        if (form1065.schedules?.scheduleK1) {
          this.formk1.push(...form1065.schedules.scheduleK1);
        }
        return;
      case GeneralSpreadsTabGroupEnum.Form1120S: {
        const renderable = taxFormData as Form1120S;
        const res = {
          renderable,
          confidence: confidence as ContextedBy<Form1120S, number>,
        };

        this.form1120Ss.push(res);

        if (renderable.schedules?.scheduleK1) {
          this.formk1.push(...renderable.schedules.scheduleK1);
        }

        return;
      }
      case GeneralSpreadsTabGroupEnum.Form1120:
        this.form1120.push(taxFormData as Form1120);
        return;
    }
  }

  private addTab(
    tabName: TabName,
    content: RenderedContent,
    group: GeneralSpreadsTabGroupEnum,
    tabType: GeneralSpreadsTabTypeEnum,
  ) {
    const { gridState, columnDefs, hoverInfos, gridOptions } = content;
    const hide8825 =
      spreadConfig.lenderSettings[this.lenderId as SupportedLenderId]?.hide8825 ?? false;
    if (hide8825 && tabType === GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT) {
      return;
    }
    const sanitizedTabName = sanitizeTabName(tabName) as TabName;
    const confidence = content.asConfidence?.() || [];
    const documentUploadIds = content.asDocumentUploadIds?.() || [];
    this.gridStates.set(sanitizedTabName, gridState);
    this.colDefs.set(sanitizedTabName, columnDefs);
    this.gridOptions.set(sanitizedTabName, gridOptions);
    this.tabGroups.push({
      tabName: sanitizedTabName,
      fullTabName: tabName,
      group,
      tabType,
    });
    this.hoverInfoData.set(sanitizedTabName, hoverInfos);
    this.confidences.set(sanitizedTabName, confidence || []);
    this.documentUploadIds.set(sanitizedTabName, documentUploadIds || []);
  }

  private process1040() {
    const taxpayerForms: Map<string, RenderableWithConfidence<Form1040>[]> = new Map();
    this.form1040s.forEach((form1040WithConfidence) => {
      const { renderable: form1040 } = form1040WithConfidence;
      const name = normalizeTaxpayerName(form1040.taxpayer, form1040.entityName);
      const forms = taxpayerForms.get(name) || [];
      taxpayerForms.set(name, [...forms, form1040WithConfidence]);
    });

    const form1040Rollups: Form1040RollUpAndTabName[] = [];
    taxpayerForms.forEach((wcs, taxpayer) => {
      const form1040Rollup = this.create1040Rollup(wcs, taxpayer);
      this.createScheduleCBreakout(wcs, taxpayer);
      this.createScheduleEBreakout(wcs, taxpayer);
      this.createScheduleFBreakout1040(wcs, taxpayer);
      form1040Rollups.push(form1040Rollup);
    });
    return form1040Rollups;
  }

  private processFinancialStatements() {
    const financialStatements = this.financialStatements;

    const renderedTabs = new Map<string, RenderedContent>();
    financialStatements.forEach((statements) => {
      // TODO: Should id be used instead? Somewhere.
      const entityName = statements.entityName;
      const year = statements.recentYear;
      statements.statements
        .filter((statement) => statement.items?.length > 0)
        .forEach((statement) => {
          const pageNumberKey = ` Page-${statement.pageNumbers[0]}`;
          const statementTypeKey =
            statement.statementType == FinancialStatementType.IncomeStatement
              ? "IS"
              : statement.statementType == FinancialStatementType.BalanceSheet
                ? "BS"
                : "CF";
          let tabName = `Fin. ${year} ${statementTypeKey} - ${entityName}`;
          if (renderedTabs.has(tabName)) {
            tabName = `Fin. ${year} ${statementTypeKey}${pageNumberKey} - ${entityName}`;
          }
          const rendered = new FinancialStatementItemsRenderedDoc(statement);
          renderedTabs.set(tabName, rendered);

          if (
            statement.statementType === FinancialStatementType.IncomeStatement &&
            statement.calculatedCashFlowItems
          ) {
            const cfTabName = tabName.includes(pageNumberKey)
              ? `CF Computed ${year}${pageNumberKey} - ${entityName}`
              : `CF Computed ${year} - ${entityName}`;
            const gridRows = Object.values(rendered.initialGridState);
            const candidateRows = cashFlowCandidateRowsFromItems(
              gridRows,
              statement.calculatedCashFlowItems || {
                interestItems: [],
                taxesItems: [],
                amortizationItems: [],
                depreciationItems: [],
                netIncomeItems: [],
              },
            );
            adjustInterestValuesForCashFlow(candidateRows, gridRows);

            if (Object.values(candidateRows).some((rows) => rows.length > 0)) {
              const cfRendered = buildCashFlowFromFinancialsTab(
                candidateRows,
                statement.availableColumns || [],
                tabName,
              );
              renderedTabs.set(cfTabName, cfRendered);
            }
          }
        });
    });

    renderedTabs.forEach((rendered, tabName) => {
      this.addTab(
        tabName as TabName,
        rendered,
        GeneralSpreadsTabGroupEnum.Financials,
        GeneralSpreadsTabTypeEnum.FINANCIALS,
      );
    });
  }

  private processAppraisalReports() {
    this.appraisalReports.forEach((report, i) => {
      const rendered = new AppraisalReportRenderedDoc(report);
      const tabName = `Appr. Report - ${report.propertyAppraisedAddress || `Prop ${i + 1}`}`;
      this.addTab(
        tabName as TabName,
        rendered,
        GeneralSpreadsTabGroupEnum.Appraisals,
        GeneralSpreadsTabTypeEnum.APPRAISALS,
      );
    });
  }

  private createScheduleCBreakout(wcs: RenderableWithConfidence<Form1040>[], taxpayer: string) {
    wcs.forEach((wc) => {
      const scheduleCs = wc.renderable.schedules?.scheduleC;
      if (!scheduleCs?.length) {
        return;
      }
      const scheduleCBreakout = new ScheduleCBreakoutRendered(scheduleCs);
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.SCHEDULE_C_BREAKOUT,
        taxpayer,
        wc.renderable.year,
      );
      this.addTab(
        tabName,
        scheduleCBreakout,
        GeneralSpreadsTabGroupEnum.Form1040,
        GeneralSpreadsTabTypeEnum.SCHEDULE_C_BREAKOUT,
      );
    });
  }

  private createScheduleFBreakout(
    scheduleFs: ScheduleF[] | undefined,
    taxpayer: string,
    year: TaxFormYear,
    group: GeneralSpreadsTabGroupEnum,
  ) {
    if (!scheduleFs?.length) {
      return;
    }
    const scheduleFBreakout = buildScheduleFBreakoutRendered(scheduleFs);
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.SCHEDULE_F_BREAKOUT,
      taxpayer,
      year,
    );
    this.addTab(tabName, scheduleFBreakout, group, GeneralSpreadsTabTypeEnum.SCHEDULE_F_BREAKOUT);
  }

  private createScheduleFBreakout1065(forms: Form1065[], taxpayer: string) {
    forms.forEach((form) => {
      this.createScheduleFBreakout(
        form.schedules?.scheduleF,
        taxpayer,
        form.year,
        GeneralSpreadsTabGroupEnum.Form1065,
      );
    });
  }

  private createScheduleFBreakout1040(
    forms: RenderableWithConfidence<Form1040>[],
    taxpayer: string,
  ) {
    forms.forEach((form) => {
      this.createScheduleFBreakout(
        form.renderable.schedules?.scheduleF,
        taxpayer,
        form.renderable.year,
        GeneralSpreadsTabGroupEnum.Form1040,
      );
    });
  }

  private createScheduleEBreakout(forms: RenderableWithConfidence<Form1040>[], taxpayer: string) {
    forms.forEach((wc) => {
      const { renderable: form } = wc;
      if (
        form.schedules?.scheduleE?.properties === undefined ||
        form.schedules?.scheduleE.properties.length === 0
      ) {
        return;
      }
      const scheduleEBreakout = new ScheduleEBreakoutRendered(form.schedules?.scheduleE);
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.SCHEDULE_E_BREAKOUT,
        taxpayer,
        form.year,
      );
      this.addTab(
        tabName,
        scheduleEBreakout,
        GeneralSpreadsTabGroupEnum.Form1040,
        GeneralSpreadsTabTypeEnum.SCHEDULE_E_BREAKOUT,
      );
    });
  }

  private k1ReferenceFor1040(form: Form1040): string {
    const k1Groups = groupK1sByEntityName(this.formk1);
    const normalizedTaxpayerName = normalizeTaxpayerName(form.taxpayer, form.entityName);
    const createK1Reference = (match: string) => {
      const k1Group = match.split("-")[1];
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.K1S_FOR_YEAR,
        k1Group,
        form.year,
      );
      const k1Count = k1Groups.get(match)?.length || 0;
      return k1Count > 0 ? `='${tabName}'!D${2 + k1Count}` : "0";
    };

    const exactMatches = Array.from(k1Groups.keys()).filter((key) => {
      const [year, entityName] = key.split("-");
      return year === form.year && entityName === normalizedTaxpayerName;
    });

    if (exactMatches.length === 1) {
      const match = exactMatches[0];
      return createK1Reference(match);
    }

    const fuzzyMatches = Array.from(k1Groups.keys()).filter((key) => {
      const [year, entityName] = key.split("-");
      return year === form.year && compareNames(entityName, normalizedTaxpayerName);
    });

    if (fuzzyMatches.length === 1) {
      const match = fuzzyMatches[0];
      return createK1Reference(match);
    }

    // no matches or multiple matches
    return "0";
  }

  private create1040Rollup(
    formsWithConfidence: RenderableWithConfidence<Form1040>[],
    taxpayer: string,
  ) {
    const formsWithBreakoutTabNames = formsWithConfidence
      .sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year))
      .map((wc) => {
        const { renderable: form } = wc;
        const scheduleCTabName = form.schedules?.scheduleC?.length
          ? this.formatTabName(GeneralSpreadsTabTypeEnum.SCHEDULE_C_BREAKOUT, taxpayer, form.year)
          : null;
        const scheduleETabName = form.schedules?.scheduleE
          ? this.formatTabName(GeneralSpreadsTabTypeEnum.SCHEDULE_E_BREAKOUT, taxpayer, form.year)
          : null;
        const scheduleFTabName = form.schedules?.scheduleF?.length
          ? this.formatTabName(GeneralSpreadsTabTypeEnum.SCHEDULE_F_BREAKOUT, taxpayer, form.year)
          : null;
        const k1TotalReference = this.k1ReferenceFor1040(form);

        return {
          wc,
          k1TotalReference,
          scheduleETabName,
          scheduleCTabName,
          scheduleFTabName,
        } as Form1040WithTabNames;
      });

    const form1040Rollup = new Form1040Grouped(formsWithBreakoutTabNames);

    const tabName = this.formatTabName(GeneralSpreadsTabTypeEnum.PCF_ALL_YEARS, taxpayer);
    this.addTab(
      tabName,
      form1040Rollup,
      GeneralSpreadsTabGroupEnum.Form1040,
      GeneralSpreadsTabTypeEnum.PCF_ALL_YEARS,
    );
    return {
      tabName,
      cashFlow: form1040Rollup,
      entityName: taxpayer,
    };
  }

  private groupContextedFormsByNormalizedName<TForm extends RenderableBase>(
    forms: RenderableWithConfidence<TForm>[],
    getName: (form: TForm) => Corporation | undefined,
  ): Map<string, RenderableWithConfidence<TForm>[]> {
    const groupedForms: Map<string, RenderableWithConfidence<TForm>[]> = new Map();
    forms.forEach((formWithConfidence) => {
      const form = formWithConfidence.renderable;
      let name = normalizeCorporationName(getName(form));
      name = name
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters
        .replace(/\b(LLC|INC|CORP|LTD|CO|COMPANY)\b/gi, "") // Remove common company designators
        .trim();
      const existingForms = groupedForms.get(name) || [];
      groupedForms.set(name, [...existingForms, formWithConfidence]);
    });
    return groupedForms;
  }

  private groupFormsByNormalizedName<TForm>(
    forms: TForm[],
    getName: (form: TForm) => Corporation | undefined,
  ): Map<string, TForm[]> {
    const groupedForms: Map<string, TForm[]> = new Map();
    forms.forEach((form) => {
      let name = normalizeCorporationName(getName(form));
      name = name
        .replace(/[^a-zA-Z0-9\s]/g, "") // Remove non-alphanumeric characters
        .replace(/\b(LLC|INC|CORP|LTD|CO|COMPANY)\b/gi, "") // Remove common company designators
        .trim();
      const existingForms = groupedForms.get(name) || [];
      groupedForms.set(name, [...existingForms, form]);
    });
    return groupedForms;
  }

  private process1120s() {
    const sCorpForms = this.groupContextedFormsByNormalizedName(
      this.form1120Ss,
      (form) => form.corporation,
    );
    const form1120sCashFlows: Form1120sCashFlowAndTabName[] = [];
    sCorpForms.forEach((formsWithConfidence) => {
      const forms = formsWithConfidence.map((wc) => wc.renderable);
      const firstForm = forms[0];
      let corporationName = normalizeCorporationName(firstForm.corporation);

      const incomeStatement = this.create1120sIncomeStatement(formsWithConfidence, corporationName);
      const balanceSheet = this.create1120sBalanceSheet(formsWithConfidence, corporationName);
      const cashflow = this.create1120sCashFlow(formsWithConfidence, corporationName);

      const ratiosRendered = new Form1120sRatiosGrouped(
        formsWithConfidence,
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS, corporationName),
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS, corporationName),
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS, corporationName),
        balanceSheet,
        incomeStatement,
        cashflow.cashFlow,
      );
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS,
        corporationName,
      );
      this.addTab(
        tabName,
        ratiosRendered,
        GeneralSpreadsTabGroupEnum.Form1120S,
        GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS,
      );
      form1120sCashFlows.push(cashflow);
    });
    return form1120sCashFlows;
  }

  private process1065() {
    const partnershipForms = this.groupFormsByNormalizedName(
      this.form1065s,
      (form) => form.partnership,
    );
    const form1065CashFlows: Form1065CashFlowAndTabName[] = [];
    partnershipForms.forEach((forms) => {
      const firstForm = forms[0];
      let corporationName = normalizeCorporationName(firstForm.partnership);

      this.createScheduleFBreakout1065(forms, corporationName);
      const incomeStatement = this.create1065IncomeStatement(forms, corporationName);
      const balanceSheet = this.create1065BalanceSheet(forms, corporationName);
      const cashFlow = this.create1065CashFlow(forms, corporationName);
      form1065CashFlows.push(cashFlow);

      const ratiosRendered = new Form1065RatiosGrouped(
        forms,
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS, corporationName),
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS, corporationName),
        this.formatTabName(GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS, corporationName),
        balanceSheet,
        incomeStatement,
        cashFlow.cashFlow,
      );
      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS,
        corporationName,
      );
      this.addTab(
        tabName,
        ratiosRendered,
        GeneralSpreadsTabGroupEnum.Form1065,
        GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS,
      );
    });
    return form1065CashFlows;
  }

  processGlobalCashFlow(
    form1040Rollups: Form1040RollUpAndTabName[],
    form1065CashFlows: Form1065CashFlowAndTabName[],
    form1120sCashFlows: Form1120sCashFlowAndTabName[],
  ) {
    const globalCashFlow = buildGlobalCashFlow(
      form1120sCashFlows,
      form1065CashFlows,
      form1040Rollups,
    );
    const tabName = GeneralSpreadsTabTypeEnum.GLOBAL_CASH_FLOW.valueOf() as TabName;
    this.addTab(
      tabName,
      globalCashFlow,
      GeneralSpreadsTabGroupEnum.Global,
      GeneralSpreadsTabTypeEnum.GLOBAL_CASH_FLOW,
    );
  }

  private create1065IncomeStatement(forms: Form1065[], corporation: string) {
    const rendered = new Form1065IncomeStatementGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1065,
      GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS,
    );
    return rendered;
  }

  private create1065BalanceSheet(forms: Form1065[], corporation: string) {
    const rendered = new Form1065BalanceSheetGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1065,
      GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS,
    );
    return rendered;
  }

  private create1065CashFlow(forms: Form1065[], corporation: string) {
    const rendered = new Form1065CashFlowGrouped(
      forms.sort((a, b) => parseInt(a.year) - parseInt(b.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1065,
      GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS,
    );
    return {
      tabName,
      cashFlow: rendered,
      entityName: corporation,
    };
  }

  private create1120sIncomeStatement(
    forms: RenderableWithConfidence<Form1120S>[],
    corporation: string,
  ) {
    const rendered = new Form1120sIncomeStatementGrouped(forms);
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1120S,
      GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS,
    );
    return rendered;
  }

  private create1120sBalanceSheet(
    forms: RenderableWithConfidence<Form1120S>[],
    corporation: string,
  ): Form1120sBalanceSheetGrouped {
    const rendered = new Form1120sBalanceSheetGrouped(
      forms.sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1120S,
      GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS,
    );
    return rendered;
  }

  private create1120sCashFlow(forms: RenderableWithConfidence<Form1120S>[], corporation: string) {
    const rendered = new Form1120sCashFlowGrouped(
      forms.sort((a, b) => parseInt(a.renderable.year) - parseInt(b.renderable.year)),
    );
    const tabName = this.formatTabName(
      GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS,
      corporation,
    );
    this.addTab(
      tabName,
      rendered,
      GeneralSpreadsTabGroupEnum.Form1120S,
      GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS,
    );
    return {
      tabName,
      cashFlow: rendered,
      entityName: corporation,
    };
  }

  private process8825() {
    this.form1065s.forEach((form, index) => {
      if (form.form8825) {
        const name = form?.partnership?.name ?? `Partnership ${index + 1}`;
        const rendered = new Form8825BreakoutRendered(form.form8825);
        const tabName = this.formatTabName(
          GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT,
          name,
          form.form8825.year,
        );
        this.addTab(
          tabName,
          rendered,
          GeneralSpreadsTabGroupEnum.Form1065,
          GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT,
        );
      }
    });
    this.form1120Ss.forEach((formWithConfidence, index) => {
      const form = formWithConfidence.renderable;
      if (form.form8825) {
        const name = form?.corporation?.name ?? `Corporation ${index + 1}`;
        const rendered = new Form8825BreakoutRendered(form.form8825);
        const tabName = this.formatTabName(
          GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT,
          name,
          form.form8825.year,
        );
        this.addTab(
          tabName,
          rendered,
          GeneralSpreadsTabGroupEnum.Form1120S,
          GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT,
        );
      }
    });
  }

  private processScheduleK1() {
    const k1Groups = groupK1sByEntityName(this.formk1);
    const sortedKeys = Array.from(k1Groups.keys()).sort();
    for (const key of sortedKeys) {
      const [year, entityName] = key.split("-");
      const k1sForGroup = k1Groups.get(key) || [];

      const tabName = this.formatTabName(
        GeneralSpreadsTabTypeEnum.K1S_FOR_YEAR,
        entityName,
        year as TaxFormYear,
      );

      const rendered = new ScheduleK1Rendered(k1sForGroup, year as TaxFormYear);
      this.addTab(
        tabName,
        rendered,
        GeneralSpreadsTabGroupEnum.K1,
        GeneralSpreadsTabTypeEnum.K1S_FOR_YEAR,
      );
    }
  }

  public formatTabName(
    tabType: GeneralSpreadsTabTypeEnum,
    entityName?: string,
    year?: TaxFormYear,
  ): TabName {
    switch (tabType) {
      case GeneralSpreadsTabTypeEnum.PCF_FOR_YEAR: {
        if (!year) {
          throw new Error("Year is required for PCF");
        }
        return `${entityName} - ${year}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.K1S_FOR_YEAR: {
        if (!year) {
          throw new Error("Year is required for K-1s");
        }
        return `K-1 - ${year} - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.PCF_ALL_YEARS: {
        return `${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.SCHEDULE_C_BREAKOUT: {
        return `Schedule C - ${year} - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.SCHEDULE_F_BREAKOUT: {
        return `Schedule F - ${year} - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.SCHEDULE_E_BREAKOUT: {
        if (!year) {
          throw new Error("Year is required for Schedule E");
        }
        return `Schedule E - ${year} - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.BUSINESS_IS_ALL_YEARS: {
        return `Bus IS - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.BUSINESS_BS_ALL_YEARS: {
        return `Bus BS - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.BUSINESS_CASH_FLOW_ALL_YEARS: {
        return `Bus CF - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.BUSINESS_RATIOS_ALL_YEARS: {
        return `Bus Ratios - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.FORM_8825_BREAKOUT: {
        if (!year) {
          throw new Error(`Year is required for ${tabType}`);
        }
        return `Form 8825 - ${year} - ${entityName}` as TabName;
      }
      case GeneralSpreadsTabTypeEnum.GLOBAL_CASH_FLOW: {
        return "Global Cash Flow" as TabName;
      }
      default: {
        throw new Error(`Unknown tab type: ${tabType}`);
      }
    }
  }
}

export function normalizeTaxpayerName(input: Person | undefined, entityName: string): string {
  if (entityName || !input || !input?.firstName || !input?.lastName) {
    return entityName;
  }
  const taxpayer = input as Person;
  const onlyFirst = taxpayer.firstName?.split(" ").shift();
  const last = taxpayer.lastName?.split(" ").pop();
  return capitalizeFirstLetterOfEachWord(`${onlyFirst} ${last}`);
}

export function normalizeCorporationName(input: Corporation | undefined): string {
  if (!input || !input.name) {
    return "";
  }

  return capitalizeFirstLetterOfEachWord(input.name.trim());
}

type RenderedContent = {
  gridState: GridState;
  gridOptions: RenderedDocOptions;
  columnDefs: ColDef[];
  hoverInfos: HoverInfo[][];
  documentUploadIds: (number | null)[][];
  asConfidence?: () => RawConfidenceContent[][];
  asDocumentUploadIds?: () => (number | null)[][];
};

export function groupK1sByEntityName(k1s: ScheduleK1[]): Map<string, ScheduleK1[]> {
  const k1List = k1s.map((k1) => ({ ...k1 }));

  // Create a map to group K1s by entity name and year
  const k1Groups: Map<string, ScheduleK1[]> = new Map();

  k1List.forEach((k1) => {
    const entityNameStarginPoint = k1.entityName || "K1 Entity";
    const entityName = entityNameStarginPoint
      .trim()
      .toLowerCase()
      .replace(/\./g, "")
      .replace(/\s+/g, " ")
      .split(" ")
      .map((word) =>
        word
          .split("'")
          .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
          .join("'"),
      )
      .join(" ");
    const key = `${k1.year}-${entityName}`;

    // Find a matching group
    let matchingGroupKey: string | undefined;
    for (const [groupKey, _] of k1Groups) {
      const [groupYear, groupEntity] = groupKey.split("-");
      // remove special characters when comparing
      const normalizedGroupEntity = groupEntity.replace(/[^a-zA-Z0-9\s]/g, "").toLowerCase();
      const normalizedEntityName = entityName.replace(/[^a-zA-Z0-9\s]/g, "").toLowerCase();
      const namesMatch = compareNames(normalizedGroupEntity, normalizedEntityName);
      if (groupYear === k1.year && namesMatch) {
        matchingGroupKey = groupKey;
        break; // Stop searching once we find a match
      }
    }

    // Add to existing group or create new one
    if (matchingGroupKey) {
      const group = k1Groups.get(matchingGroupKey)!;
      group.push(k1);
    } else {
      k1Groups.set(key, [k1]);
    }
  });

  return k1Groups;
}
