import { createAction } from "@reduxjs/toolkit";
import { CompletedDocumentUpload } from "src/contracts/intake/answer";
import { BorrowerIntakeSection } from "src/models/BorrowerIntakePage";
import { Answer, AnswerContext, Inputs } from "src/models/BorrowerIntakePage/answer";
import { UploadDocumentResponse } from "@interfold-ai/shared/models/DocumentRequest";
import { BorrowerIntakeAction } from "./actions.constants";
import { Review } from "src/models/Review";
import { Loan } from "src/models/Loan";
import { AddAnyDocumentState } from "src/page-components/investment-detail/add-document/AddAnyDocument";
import { DocumentQuality } from "@interfold-ai/shared/enums/DocumentQualityEnum";

export interface FetchAnswerPayload {
  questionId: number;
}
export const fetchAnswerAction = createAction<FetchAnswerPayload>(
  BorrowerIntakeAction.FETCH_ANSWER,
);
export const fetchAnswerCompletedAction = createAction<{
  answers: Answer[];
  documentUploads?: CompletedDocumentUpload[];
  initialEntityName: string;
  borrowerEntityId?: number;
  loanId: number;
}>(BorrowerIntakeAction.FETCH_ANSWER_COMPLETED);

export const fetchAnswerErrorAction = createAction<{
  errors: string[];
}>(BorrowerIntakeAction.FETCH_ANSWER_ERROR);

export const createQuestionAction = createAction<{
  section: BorrowerIntakeSection;
  page: number;
  response: Inputs;
  answers: Answer[];
  context: AnswerContext;
  isOverview3Completed?: boolean;
  modifyCustomerUserData?: { emails: string[]; loanId: number; returnResponse: boolean };
  callback?: () => void;
}>(BorrowerIntakeAction.CREATE_QUESTION);

export const createQuestionCompletedAction = createAction(
  BorrowerIntakeAction.CREATE_QUESTION_COMPLETED,
);

export const createQuestionErrorAction = (message: string) => ({
  type: BorrowerIntakeAction.CREATE_QUESTION_ERROR,
  payload: message,
});

export const reviewDocumentUpload = createAction<{
  payload: any;
  documentRequestId: number;
  lenderId: number;
  key: string;
  formik: any;
  formIndex?: number;
}>(BorrowerIntakeAction.REVIEW_UPLOAD_DOCUMENT);

export const reviewDocumentCompletedUpload = createAction<{
  reviews: Review[];
}>(BorrowerIntakeAction.REVIEW_UPLOAD_DOCUMENT_COMPLETED);

export const documentUploadAction = createAction<{
  section: BorrowerIntakeSection;
  payload: any;
  title: string;
  documentTypeId: number;
  documentYear: string;
  documentQuarter?: string;
  documentMonth?: string;
  loanId: number;
  entityId: number;
  page: number;
  key: string;
  formik: any;
  formIndex?: number;
  isReview?: boolean;
  guarantor?: {
    name: string;
  };
  displayName?: string;
  extraDescription?: string;
}>(BorrowerIntakeAction.UPLOAD_DOCUMENT);

export const documentUploadCompletedAction = (
  section: BorrowerIntakeSection,
  file: File,
  document: UploadDocumentResponse,
  documentTypeId: number,
) => ({
  type: BorrowerIntakeAction.UPLOAD_DOCUMENT_COMPLETED,
  payload: {
    document,
    documentTypeId,
    section,
  },
  meta: { file },
});

export const documentUploadProgressAction = (file: File, progress: number) => ({
  type: BorrowerIntakeAction.UPLOAD_DOCUMENT_PROGRESS,
  payload: progress,
  meta: { file },
});

export const documentUploadErrorAction = (error: Error) => ({
  type: BorrowerIntakeAction.UPLOAD_DOCUMENT_ERROR,
  payload: error,
});

export const guarantorPageUpdateAction = (updatePage: number) => ({
  type: BorrowerIntakeAction.GUARANTOR_PAGE_UPDATE,
  payload: updatePage,
});

export const bulkUploadDocumentAction = (
  loanId: number,
  entityId: number,
  section: BorrowerIntakeSection,
  // Sent properly from Add Document popup, but needs update in overview side after header implementation
  // Values: Collateral | Borrower 1 - John Brown | Borrower 2 - Marta Hills | etc.
  sectionTitle: string,
  documentsData: {
    key: string;
    files: any;
    documentTypeId: number | null;
    documentTitle: string | null;
    documentYear: string | null;
    documentQuality?: DocumentQuality;
    documentQuarter?: string;
    documentMonth?: string;
    isReview: boolean;
    documentPeriodJson?: string;
    extraDescription?: string;
  }[],
) => {
  return {
    type: BorrowerIntakeAction.BULK_UPLOAD_DOCUMENT,
    payload: {
      loanId,
      entityId,
      documentsData,
      section,
      sectionTitle,
    },
  };
};

export const bulkUploadDocumentCompleteAction = () => {
  return {
    type: BorrowerIntakeAction.BULK_UPLOAD_DOCUMENT_COMPLETE,
  };
};

export const lenderSideDocumentUploadActionComplete = (
  section: BorrowerIntakeSection,
  document: UploadDocumentResponse,
) => {
  return {
    type: BorrowerIntakeAction.LENDER_SIDE_UPLOAD_DOCUMENT_COMPLETE,
    payload: {
      ...document,
      section,
    },
  };
};

export const dropUploadDocumentAction = (
  section: string,
  documentName: string,
  documentTypeId: number | null = null,
  documentTitle: string | null = null,
  documentYear: string | null = null,
) => {
  return {
    type: BorrowerIntakeAction.DROP_UPLOADING_DOCUMENT,
    payload: {
      documentTypeId,
      documentTitle,
      documentYear,
      documentName,
      section,
    },
  };
};

export const areAllSectionCompletedAction = (update: boolean) => ({
  type: BorrowerIntakeAction.ARE_ALL_SECTIONS_COMPLETED_UPDATE,
  payload: update,
});

export const isOverviewCompletedAction = (update: boolean) => ({
  type: BorrowerIntakeAction.IS_OVERVIEW_COMPLETED,
  payload: update,
});

export const saveFetchedAnswerAction = createAction<{
  section: BorrowerIntakeSection;
  page: number;
  response: Inputs;
  answers: Answer[];
}>(BorrowerIntakeAction.SAVE_FETCHED_ANSWER);

export const navBarLoadingAction = (update: boolean) => ({
  type: BorrowerIntakeAction.NAVBAR_LOADING,
  payload: update,
});

// FOR DEMO PURPOSE, COMBINE THIS LOGIC WITH API CALL
export const saveLoanDescription = (desc: any) => ({
  type: BorrowerIntakeAction.SAVE_LOAN_DESCRIPTION,
  payload: desc,
});

export type OpenAddAnyFileModalProps = Omit<
  AddAnyDocumentState,
  "addAnyFileModalOpen" | "allowDuplicate"
>;

export const openAddAnyFileModalAction = (payload: OpenAddAnyFileModalProps) => ({
  type: BorrowerIntakeAction.OPEN_ADD_ANY_FILE_MODAL,
  payload: {
    ...payload,
    addAnyFileModalOpen: true,
  } as AddAnyDocumentState,
});

export const closeAddAnyFileModal = () => ({
  type: BorrowerIntakeAction.CLOSE_ADD_ANY_FILE_MODAL,
});

export const disableAddAnyFileUpload = () => ({
  type: BorrowerIntakeAction.DISABLE_ADD_ANY_FILE_UPLOAD,
});

export const enableAddAnyFileUpload = () => ({
  type: BorrowerIntakeAction.ENABLE_ADD_ANY_FILE_UPLOAD,
});

export const setDefaultAddAnyEntity = (entity: { entityId: number }) => ({
  type: BorrowerIntakeAction.SET_DEFAULT_ADD_ANY_ENTITY,
  payload: entity,
});

export const multipleDocumentUploadAction = (
  loanId: number,
  entityId: number,
  section: BorrowerIntakeSection,
  documentsData: {
    key: string;
    files: any;
    isReview: boolean;
  }[],
) => {
  return {
    type: BorrowerIntakeAction.MULTI_DOCUMENT_UPLOAD,
    payload: {
      loanId,
      entityId,
      section,
      documentsData,
    },
  };
};

export const multipleDocumentDeleteAction = (
  loanId: number,
  documentRequestId: number,
  isReview: boolean,
) => {
  return {
    type: BorrowerIntakeAction.MULTI_DOCUMENT_DELETE,
    payload: {
      loanId,
      documentRequestId,
      isReview,
    },
  };
};
