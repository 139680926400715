import { Form1120S } from "@interfold-ai/shared/models/tax/Form1120s";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";
import { AutoRenderedSheetBuilderWithConfidence } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";
import { HoverLabel } from "src/classes/RenderedDoc";

export const Form1120sCashFlowRowHeadings = {
  NetIncome: "Net income",
  Interest: "Interest",
  Depreciation: "Depreciation",
  Amortization: "Amortization",
  AmortizationWarning: "Amortization ‡",
  GainLossOnFixedAssetSale: "(Gain)/Loss on fixed asset sale",
  ExtraordinaryGainLoss: "Extraordinary (gain)/loss *",
  BusinessCashFlowBeforeTax: "Business cash flow before tax",
  TotalTax: "Total tax",
  BusinessCashFlowAfterTax: "Business cash flow after tax",
  LessDistributionsDividends: "Less: Distributions / Dividends",
  LessOtherCfAdjustment: "Less: Other CF adjustment *",
  BusinessCashFlowAvailableForDebtService: "Business cash flow available for debt service",
  DebtService: "Debt service  ",
  ProjectedDebtService: "Projected debt service",
  DebtServiceCoverageRatio: "Debt service coverage ratio",
  StressScenarioDebtService: "Stress scenario - debt service",
  InterestRateStress: "Interest rate stress",
  HypotheticalDebtServiceCoverageRatio: "Hypothetical debt service coverage ratio",
};

export const buildForm1120sCashFlow = (
  data: RenderableWithConfidence<Form1120S>,
  startingColumn: string,
  startingRow: number,
): AutoRenderedSheetBuilderWithConfidence<Form1120S, typeof Form1120sCashFlowRowHeadings> => {
  const ret = AutoRenderedSheetBuilderWithConfidence.from(
    data,
    Form1120sCashFlowRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRowFromData("NetIncome", (data) => data.schedules?.scheduleM1?.netIncomeLoss ?? 0)
    .addRowFromData("Interest", (data) => data.deductions?.interest ?? 0)
    .addRowWithFormula("Depreciation", (_finders, _labels, data) => {
      const depreciation = data.deductions?.depreciation ?? 0;
      const section179 = data.schedules?.scheduleK?.section179Deduction ?? 0;
      const deductionsDepreciation =
        data.schedules?.scheduleM1?.deductionsIncludedOnScheduleKDepreciation ?? 0;
      const expensesDepreciation =
        data.schedules?.scheduleM1?.expensesRecordedOnBooksDepreciation ?? 0;
      return `=${depreciation} + ${section179} - ${deductionsDepreciation} + ${expensesDepreciation}`;
    })
    .addRowFromData("Amortization", (data) => data.deductions?.amortization?.statementValue ?? 0)
    .addRowFromData("GainLossOnFixedAssetSale", (data) => {
      const gainLoss = data.income?.netGainOrLossFromForm4797 ?? 0;
      return gainLoss ? gainLoss * -1 : 0;
    })
    .addRowWithStaticValue(Form1120sCashFlowRowHeadings.ExtraordinaryGainLoss, "0")
    .addRowWithFormula("BusinessCashFlowBeforeTax", (finders, labels) => {
      const startCell = finders.columnReference(labels.NetIncome);
      const endCell = finders.labelReference(Form1120sCashFlowRowHeadings.ExtraordinaryGainLoss);
      return `=SUM(${startCell}:${endCell})`;
    })
    .addRowWithStaticValue("TotalTax", "0")
    .addRowWithFormula("BusinessCashFlowAfterTax", (finders, labels) => {
      const beforeTax = finders.columnReference(labels.BusinessCashFlowBeforeTax);
      const totalTax = finders.labelReference(labels.TotalTax);
      return `=${beforeTax} - ${totalTax}`;
    })
    .addRowFromData(
      "LessDistributionsDividends",
      (data) => data.schedules?.scheduleM2?.distributions?.accumulatedAdjustments ?? 0,
    )
    .addRowWithStaticValue(Form1120sCashFlowRowHeadings.LessOtherCfAdjustment, "0")
    .addRowWithFormula("BusinessCashFlowAvailableForDebtService", (finders, labels) => {
      const afterTax = finders.columnReference(labels.BusinessCashFlowAfterTax);
      const distributions = finders.columnReference(labels.LessDistributionsDividends);
      const otherAdj = finders.labelReference(Form1120sCashFlowRowHeadings.LessOtherCfAdjustment);
      return `=${afterTax} - ${distributions} - ${otherAdj}`;
    })
    .addEmptyRow()
    .addRowWithStaticValue("DebtService", "")
    .addRowWithStaticValue("ProjectedDebtService", "0")
    .addRowWithFormula("DebtServiceCoverageRatio", (finders, labels) => {
      const availableCashFlow = finders.columnReference(
        labels.BusinessCashFlowAvailableForDebtService,
      );
      const projectedDebtService = finders.columnReference(labels.ProjectedDebtService);
      return `=${availableCashFlow} / ${projectedDebtService}`;
    })
    .addEmptyRow()
    .addRowWithStaticValue("StressScenarioDebtService", "")
    .addRowWithFormula("ProjectedDebtService", (finders, labels) => {
      const projectedDebtService = finders.columnReference(labels.ProjectedDebtService);
      return `=${projectedDebtService}`;
    })
    .addRowWithStaticValue("InterestRateStress", "0")
    .addRowWithFormula("HypotheticalDebtServiceCoverageRatio", (finders, labels) => {
      const availableCashFlow = finders.columnReference(
        labels.BusinessCashFlowAvailableForDebtService,
      );
      const projectedDebtServiceEnd = finders.columnReference(labels.ProjectedDebtService, "end");
      const interestRateStress = finders.columnReference(labels.InterestRateStress);
      return `=${availableCashFlow} / (${projectedDebtServiceEnd} + ${interestRateStress})`;
    });

  ret.setHoverInfoForLabel(
    Form1120sCashFlowRowHeadings.Depreciation,
    HoverLabel.from(
      `Depreciation = Depreciation + Section 179 Deduction - Deductions on Return Depreciation + Expenses Recorded on Books Depreciation`,
    ),
  );
  return ret;
};
