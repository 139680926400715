import { CellValue, RawCellContent } from "hyperformula";
import { CellClassParams, ColDef, ColSpanParams, EditableCallbackParams } from "ag-grid-community";
import { v4 as uuidv4 } from "uuid";
import { HFCellEditor } from "src/components/spreads/HyperFormulaCellEditor";
import { HFCellRenderer } from "src/components/spreads/HyperFormulaCellRenderer";
import { GridCellObject } from "src/components/spreads/GridCellObject";
import { GridState, GridRowDataType, RowStyle } from "./GridState";
import { HFHeaderComponent } from "src/components/spreads/HyperFormulaHeaderComponent";
import { RenderableBase } from "@interfold-ai/shared/models/render/common";

const LEFT_PADDING_PX_EACH_LEVEL = 15;
const LEFT_PADDING_PX_DEFAULT = 15;

export type RowLabel = string;

export const emptyGridState = {
  rowDataArray: ["", ""],
  rowDataType: "text",
  rowStyle: "standard",
  isManagedByApp: false,
};

export function asArray(gridState: GridState): RawCellContent[][] {
  if (!gridState) {
    return [];
  }
  return Object.values(gridState).map((row) => row.rowDataArray);
}

/**
 * Converts a one-based column index to an Excel column label (e.g. "A", "B", "C", "AA", "AB", etc.).
 * @param num - The one-based column index to convert.
 * @returns The Excel column label.
 */
export const colNumberToExcelCol = (num: number) => {
  let letters = "";
  while (num > 0) {
    let mod = (num - 1) % 26;
    letters = String.fromCharCode(65 + mod) + letters;
    num = Math.floor((num - mod) / 26);
  }
  return letters;
};

/**
 * Converts an Excel column label (e.g. "A", "B", "C", "AA", "AB", etc.) to a zero-based column index.
 * This is how HyperFormula and JavaScript count columns, starting with 0.
 * @param colLabel - The Excel column label to convert.
 * @returns The zero-based column index.
 */
export const excelColToColIndex = (colLabel: string): number => {
  let num = 0;
  for (let i = 0; i < colLabel.length; i++) {
    num = num * 26 + (colLabel.charCodeAt(i) - 64);
  }
  return num - 1; // Subtract 1 to make it zero-based
};

/**
 * Converts an Excel column label (e.g. "A", "B", "C", etc.) to a one-based column number.
 * This is how humans count columns, starting with 1.
 * @param colId - The Excel column label to convert.
 * @returns The one-based column number.
 */
export const excelColToColNumber = (colId: string): number => {
  return excelColToColIndex(colId) + 1;
};

export type RawSheetData = Record<string, CellValue[][]>;

export function guessRowDataType(row: RawCellContent[]): GridRowDataType {
  if (row.some((cell) => cell instanceof Date)) {
    return "date";
  } else if (row.some((cell) => cell?.toString().startsWith("="))) {
    return "number";
  }
  if (row.some((cell) => typeof cell === "number")) {
    return "number";
  } else {
    return "text";
  }
}

export function createGridEntry(
  rowDataArray: RawCellContent[],
  rowDataType: GridRowDataType,
  rowStyle: RowStyle,
  index: number,
  isManagedByApp: boolean = true,
): GridState[keyof GridState] {
  return { rowDataArray, rowDataType, rowStyle, isManagedByApp, index };
}

export function highlightedTextRow(
  rowDataArray: RawCellContent[],
  index: number,
): GridState[keyof GridState] {
  return createGridEntry(rowDataArray, "text", "highlighted", index);
}

export function standardTextRow(
  rowDataArray: RawCellContent[],
  index: number,
): GridState[keyof GridState] {
  return createGridEntry(rowDataArray, "text", "standard", index);
}

export function highlightedNumberRow(
  rowDataArray: RawCellContent[],
  index: number,
): GridState[keyof GridState] {
  return createGridEntry(rowDataArray, "number", "highlighted", index);
}

export function standardNumberRow(
  rowDataArray: RawCellContent[],
  index: number,
): GridState[keyof GridState] {
  return createGridEntry(rowDataArray, "number", "standard", index);
}

export function metadataTextRow(
  rowDataArray: RawCellContent[],
  index: number,
): GridState[keyof GridState] {
  return createGridEntry(rowDataArray, "text", "metadata", index);
}

export function metaDataDateRow(
  rowDataArray: RawCellContent[],
  index: number,
): GridState[keyof GridState] {
  return createGridEntry(rowDataArray, "date", "metadata", index);
}

// Define a type for dynamic cell data, separate from the 'id'.
export type DynamicCellData = {
  [key: string]: GridCellObject;
};

// Define the RowData type as an intersection of a specific structure and dynamic cell data.
export type GridRowData = {
  id: string;
} & DynamicCellData;

// Used in `asRows` method `reduce` accumulator.  Allow for dynamic string keys.
export type AsRowsAccType = {
  [key: string]: GridCellObject;
};

export type DefaultRowLabelsSubset<Labels extends ReadonlyArray<string>> = Extract<
  Labels[number],
  string
>[];

export const WhiteColor = "#FFFFFF";
export const TableHightlightColor = "#F7F9FB";
export const TableHighlightBlueColor = "#E7EDF4";

export type HoverInfoType = "auto" | "formula" | "text"; // | "pdf-preview"

export class HoverInfoBase {
  type: HoverInfoType = "auto";
}

export interface HoverInfo extends HoverInfoBase {}

export class HoverLabel implements HoverInfoBase {
  type: HoverInfoType = "text";
  label: string;

  constructor(label: string) {
    this.label = label;
  }

  static from(label: string): HoverLabel {
    return new HoverLabel(label);
  }
}

export class HoverFormula extends HoverInfoBase {
  type: HoverInfoType = "formula";
}

export abstract class Rendered<T extends RenderableBase, ValidLabels extends string>
  implements RenderedDoc
{
  underlying: T;
  abstract initialGridState: GridState;
  abstract numberOfColumns: number;
  public hoverInfos: HoverInfo[][] = [];
  public documentUploadIds: (number | null)[][] = [];
  // a mapping of cell addresses to column span widths
  public colSpans: Record<string, number> = {};

  constructor(underlying: T) {
    this.underlying = underlying;
  }

  private _gridState?: GridState;
  private _gridOptions?: RenderedDocOptions;

  /* `gridState` holds an unified version of the data required to render Sheets.
   *  Specifically, its contents are structured so that the data formats required by both
   *  HyperFormula (for Excel-like functionality) and AG Grid (for UI display)
   *  can be easily derived, via this class's `asArray` and `asRows` methods.
   */
  get gridState(): GridState {
    if (!this._gridState) {
      this._gridState = this.initialGridState;
    }
    return this._gridState;
  }

  get gridOptions(): RenderedDocOptions {
    return this._gridOptions ?? DEFAULT_RENDERED_DOC_OPTIONS;
  }

  set gridOptions(newOptions: RenderedDocOptions) {
    this._gridOptions = newOptions;
  }

  get headers(): string[] {
    return this.generateCapitalLetters(this.numberOfColumns);
  }

  get rows() {
    return this.asRows();
  }

  /*  `columnDefs` is an array of column definitions used by AG Grid.
   *  ===============================================================
   *  - Associates column headers ('A','B', 'C', etc.) with cell values.
   *  - Controls style, width, and components used for rendering UI headers and cells.
   *
   *  - IMPORTANT: We pass cell values in a format that AG Grid does NOT natively expect.
   *    For example, we pass in `A: { value: '2023', style: 'highlighted', ... }` instead of `A: 2023`.
   *    The `valueGetter` tells AG Grid how to work with our cell value formats.  That's
   *    important, b/c our custom `cellRenderer` controls the UI of cells when they are NOT being
   *    edited, but AG Grid still needs access to the cell value for editing purposes.
   */
  get columnDefs(): ColDef[] {
    const columnHeaders = this.headers;
    const columnDefs = columnHeaders.map((columnHeader, columnIndex) => {
      return {
        headerName: columnHeader,
        colId: columnHeader,
        field: columnHeader,
        headerComponent: HFHeaderComponent,
        cellEditor: HFCellEditor,
        cellRenderer: HFCellRenderer,
        sortable: false,
        minWidth: columnIndex === 0 ? 350 : 150,
        flex: columnIndex === 0 ? 2 : 1,
        editable: this.checkIfEditable,
        cellStyle: this.getCellStyleFunction,
        colSpan: getColSpan(this.colSpans),
      };
    });

    return columnDefs;
  }

  abstract get defaultRowLabels(): ValidLabels[];

  abstract get highlightedRowLabels(): ValidLabels[];

  abstract get percentageRowLabels(): ValidLabels[];

  abstract get metadataRowLabels(): ValidLabels[];

  getHoverState(_rowIdx: number, _colIdx: number): HoverInfo {
    return { type: "auto" };
  }

  /*
   *  In order to support styling and numbe formats, we pass cell values in a format that AG Grid does natively expect.
   *  For example, we pass in
   *    `A: { value: '2023', style: 'highlighted', ... }`
   *  instead of
   *    `A: 2023`.
   *  See `columnDefs` (and its `valueGetter` property) for more.
   */
  asRows(): GridRowData[] {
    const rows = Object.entries(this.gridState).map(([key, value]) => {
      return {
        id: key,
        ...value.rowDataArray.reduce((acc: AsRowsAccType, cur: any, index: number) => {
          const columnName = this.colNumberToExcelCol(index + 1); // Convert index to column name
          acc[columnName] = {
            value: cur,
            style: value.rowStyle,
            metadata: value.rowMetadata,
            type: index === 0 ? "text" : value.rowDataType,
            isManagedByApp: value.isManagedByApp,
            isEditable: value.editable,
          };
          return acc;
        }, {} as AsRowsAccType),
      };
    });

    return rows as GridRowData[];
  }

  colNumberToExcelCol(num: number): string {
    return colNumberToExcelCol(num);
  }
  excelColToColIndex(columnId: string): number {
    return excelColToColIndex(columnId);
  }
  columnIndexToExcelCol(index: number): string {
    return this.colNumberToExcelCol(index + 1);
  }

  /* Helper methods used in subclasses
   * - `generateRepeatedValues` creates an array of repeated values
   * - `generateReference` used in summary sheets (e.g. properties by year) to refer to original sheet
   */

  // `generateRepeatedMethod` method subtracts 1 from the number of columns, in anticipation that
  // the row label will always be provided.  So, e.g., the 'rowDataArray` for a row with blank rows

  // `generateFormulaByColumnPattern accepts will generate an array of Excel-like formulas, provided:
  // - (1) the formula pattern only depends on the column label, and

  /**
   * Updates the value of a cell in the gridState based on a zero-based row index.
   * @param rowIndex The zero-based row index to update.
   * @param columnId The column ID (e.g., 'A', 'B', 'C') of the cell to update.
   * @param newValue The new value to set in the specified cell.
   */
  public updateGridStateCellValue(
    rowIndex: number,
    columnId: string,
    newValue: RawCellContent,
  ): void {
    const entries = Object.entries(this._gridState || {});

    // Ensure the row index is within the bounds of the grid state entries.
    if (rowIndex < 0 || rowIndex >= entries.length) {
      console.warn(`Row index ${rowIndex} is out of bounds.`);
      return;
    }

    const entry = entries[rowIndex];
    const [_, value] = entry;
    const columnIndex = this.excelColToColIndex(columnId);

    // Ensure the column index is within the bounds of the rowDataArray.
    if (columnIndex < 0 || columnIndex >= value.rowDataArray.length) {
      console.warn(`Column index ${columnIndex} is out of bounds.`);
      return;
    }

    // Update the value at the specified position & rebuild _gridState with the updated entry
    value.rowDataArray[columnIndex] = newValue;
    this._gridState = Object.fromEntries(entries);
  }

  /**
   * Adds a new row to the _gridState object at the specified position.
   * @param rowIndex The index at which to add the new row.
   * @param where Specifies where to add the new row relative to rowIndex ("above" or "below").
   */
  public addGridStateRow(rowIndex: number, where: "above" | "below"): string[] {
    const entries = Object.entries(this._gridState || {});
    const newRowKey = uuidv4();
    const rowDataArray = this.headers.map(() => "");
    const newRowObject = {
      rowDataArray, // Blank strings for each header
      rowStyle: "standard" as RowStyle,
      rowDataType: "text" as GridRowDataType,
      isManagedByApp: false,
    };

    // Determine the position to insert the new row based on 'where' argument
    const insertPosition = where === "above" ? rowIndex : rowIndex + 1;

    // Insert the new row into the entries array at the determined position
    entries.splice(insertPosition, 0, [newRowKey, newRowObject]);

    // Rebuild the _gridState object with the new row added
    this._gridState = Object.fromEntries(entries);
    return rowDataArray;
  }

  // still needs a leading blank string, e.g. ["", ...this.generateRepeatedValues(3, "")]
  generateRepeatedValues(numberOfColumns: number, value: RawCellContent): RawCellContent[] {
    if (numberOfColumns <= 1) {
      return [];
    } else {
      return new Array(numberOfColumns - 1).fill(value);
    }
  }

  // - (2) the formula is meant to start in column B (with column A being used for row labels).
  generateFormulaByColumnPattern(
    numberOfColumns: number,
    formulaPattern: (colLabel: string) => string,
  ): string[] {
    const formulas = new Array(numberOfColumns - 1).fill(null).map((_, colIndex) => {
      const colLabel = this.colNumberToExcelCol(colIndex + 2); // Start from "B"
      return formulaPattern(colLabel);
    });
    return formulas;
  }

  // TODO --> Update or use this????
  generateReference(docName: string, colIndex: number, row: number): string {
    const colLetter = colNumberToExcelCol(colIndex);
    return `'${docName}'!${colLetter}${row + 1}`; // Adding 1 to row as Excel rows start at 1
  }

  /*  `checkIfEditable` tells AG Grid if cell can be edited of not, via columnDefs.
   *  =============================================================================
   *  Row labels (i.e. column A) added by the app are NOT editable.
   *  Row labels added by the user and all cells with values/formulas are editable.
   */
  checkIfEditable(params: EditableCallbackParams<GridRowData, GridCellObject>) {
    return checkIfEditable(params);
  }

  /*  `getCellStyleFunction` tells AG Grid how to style cells, via columnDefs.
   */
  getCellStyleFunction(params: CellClassParams<GridRowData, GridCellObject>) {
    const colId = params?.column?.getColId();
    const cellStyle = params?.value?.style;

    const justifyContent = colId === "A" ? "flex-start" : "flex-end";
    const paddingLeft = `${
      LEFT_PADDING_PX_DEFAULT +
      (params.value?.metadata?.levelIndex ?? 0) * LEFT_PADDING_PX_EACH_LEVEL
    }px`;

    if (cellStyle === "highlighted") {
      return {
        fontWeight: "600",
        justifyContent: justifyContent,
        background: TableHighlightBlueColor,
        paddingLeft,
      };
    } else if (cellStyle === "metadata") {
      return {
        fontWeight: "normal",
        justifyContent: justifyContent,
        background: TableHighlightBlueColor,
        paddingLeft,
      };
    } else {
      // For cellStyle === "standard"
      return {
        fontWeight: "normal",
        justifyContent: justifyContent,
        background: WhiteColor,
        paddingLeft,
      };
    }
  }

  /*  Get `headers`, an array of capital letters starting with 'A'; e.g. ['A', 'B', 'C', ... ].
   *  Headers is thus based on the number of columns, which is set in the subclass.
   */
  private generateCapitalLetters(n: number): string[] {
    const letters: string[] = [];
    for (let i = 0; i < n; i++) {
      letters.push(this.colNumberToExcelCol(i + 1));
    }
    return letters;
  }
}

export interface ColumnLabels {
  colLabels: string[];
}

export interface RenderedDocOptions {
  supressContextMenu: boolean;
}

export const DEFAULT_RENDERED_DOC_OPTIONS: RenderedDocOptions = {
  supressContextMenu: false,
};

export interface RenderedDoc {
  underlying: RenderableBase;
  gridState: GridState;
  gridOptions: RenderedDocOptions;
  // asGrid(rendered: boolean): RowData[];
  headers: string[];
  rows: GridRowData[]; // TODO --> Remove this and incorporate with ID functionality somewhere else
  columnDefs: ColDef[];
  hoverInfos: HoverInfo[][];
  colSpans: Record<string, number>;
}

export function checkIfEditable(params: EditableCallbackParams<GridRowData, GridCellObject>) {
  const colId = params?.column?.getColId();
  const isRowManagedByApp = params?.data?.[colId]?.isManagedByApp;
  const isEditable = params?.data?.[colId]?.isEditable;
  const isCellEditable = !isRowManagedByApp
    ? true // Not managed by app - always editable
    : colId === "A"
      ? false // Column A managed by app - never editable
      : isEditable === undefined || isEditable === null
        ? true // If isEditable not specified, default to true
        : isEditable; // Otherwise use specified isEditable value

  return isCellEditable;
}

export function getCellStyleFunction(params: CellClassParams<GridRowData, GridCellObject>) {
  const colId = params?.column?.getColId();
  const cellStyle = params?.value?.style;

  const justifyContent = colId === "A" ? "flex-start" : "flex-end";
  const paddingLeft = `${
    LEFT_PADDING_PX_DEFAULT + (params.value?.metadata?.levelIndex ?? 0) * LEFT_PADDING_PX_EACH_LEVEL
  }px`;

  if (cellStyle === "highlighted") {
    return {
      fontWeight: "600",
      justifyContent: justifyContent,
      background: TableHighlightBlueColor,
      paddingLeft,
    };
  } else if (cellStyle === "metadata") {
    return {
      fontWeight: "normal",
      justifyContent: justifyContent,
      background: TableHighlightBlueColor,
      paddingLeft,
    };
  } else {
    // For cellStyle === "standard"
    return {
      fontWeight: "normal",
      justifyContent: justifyContent,
      background: WhiteColor,
      paddingLeft,
    };
  }
}

export function getColSpan(colSpans: Record<string, number> | null | undefined) {
  return ({ colDef, node }: ColSpanParams<GridRowData, RawCellContent>) => {
    const cleanColSpans = colSpans ?? {};
    const cleanRowIndex = node?.rowIndex ?? 0;
    const cellId = `${colDef.field}${cleanRowIndex + 1}`; // e.g. "A1", "B2", etc.
    return cleanColSpans[cellId] ?? 1;
  };
}
