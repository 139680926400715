import { ScheduleF } from "@interfold-ai/shared/models/tax/schedules/ScheduleF";
import { AutoRendered } from "../AutoRendered";
import { AutoRenderedSheetBuilder } from "./AutoRenderedSheetBuilder";
import { Labels } from "./Labels";

const ScheduleFBreakoutRowHeadings: Labels = {
  NameOfProprietor: "Name of proprietor",
  NetFarmProfitOrLoss: "Net farm profit or loss",
  Depreciation: "Depreciation",
  MortgageInterest: "Mortgage Interest",
  OtherInterest: "Other Interest",
  Amortization: "Amortization",
  ScheduleFEBIDA: "Schedule F EBIDA",
};
export type ScheduleFBreakoutRendered = AutoRendered<ScheduleF[]>;
export function buildScheduleFBreakoutRendered(schedules: ScheduleF[]): ScheduleFBreakoutRendered {
  const warnForAmortization = schedules.some((schedule) => !!schedule.amortization.warning);
  const amortizationLabel = warnForAmortization
    ? `${ScheduleFBreakoutRowHeadings.Amortization} ‡`
    : ScheduleFBreakoutRowHeadings.Amortization;
  const builder = new AutoRenderedSheetBuilder(schedules, ScheduleFBreakoutRowHeadings, 1, "A");
  const sumThisRow = (rowNumber: number) => {
    const startingLetter = builder.colNumberToExcelCol(2);
    const endingLetter = builder.colNumberToExcelCol(builder.underlying.length + 1);
    return `=SUM(${startingLetter}${rowNumber}:${endingLetter}${rowNumber})`;
  };
  builder.highlightedRowLabels = [
    { NameOfProprietor: ScheduleFBreakoutRowHeadings.NameOfProprietor },
    { ScheduleFEBIDA: ScheduleFBreakoutRowHeadings.ScheduleFEBIDA },
  ];
  builder
    .addRow(({ data, labels }) => [
      labels.NameOfProprietor,
      ...data.map((schedule) => schedule.farmName),
      "Total",
    ])
    .addRow(({ data, labels, rowNumber }) => [
      labels.NetFarmProfitOrLoss,
      ...data.map((schedule) => schedule.netFarmProfitOrLoss),
      sumThisRow(rowNumber),
    ])
    .addRow(({ data, labels, rowNumber }) => [
      labels.Depreciation,
      ...data.map((schedule) => schedule.depreciation),
      sumThisRow(rowNumber),
    ])
    .addRow(({ data, labels, rowNumber }) => [
      labels.MortgageInterest,
      ...data.map((schedule) => schedule.interestMortgage),
      sumThisRow(rowNumber),
    ])
    .addRow(({ data, labels, rowNumber }) => [
      labels.OtherInterest,
      ...data.map((schedule) => schedule.interestOther),
      sumThisRow(rowNumber),
    ])
    .addRow(({ data, rowNumber }) => [
      amortizationLabel,
      ...data.map((schedule) => schedule.amortization.statementValue),
      sumThisRow(rowNumber),
    ])
    .addRow(({ data, labels, rowNumber }) => [
      labels.ScheduleFEBIDA,
      ...data.map((_schedule, index) => {
        const column = builder.colNumberToExcelCol(index + 2);
        return `=SUM(${column}2:${column}${rowNumber - 1})`;
      }),
      sumThisRow(rowNumber),
    ]);
  return builder;
}
