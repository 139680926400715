import { RawCellContent } from "hyperformula";
import { LenderSpreadSettings, SupportedLenderId } from "@interfold-ai/shared/models/SpreadsConfig";
import spreadConfig from "@interfold-ai/shared/spreads-config";
import { AutoRendered } from "../AutoRendered";

export interface DSCRCalculatorData {
  noiRow: number;
  debtServiceRows: number[];
  startingColumn: string;
  lenderId: SupportedLenderId;
  propertyColumnCount: number;
  rollupOnly: boolean;
}

const DSCR_COLUMN_LABEL = "Debt Service Coverage Ratio";
const TARGET_DSCR_COLUMN_LABEL = "Target DSCR";
export class DSCRCalculatorRendered extends AutoRendered<DSCRCalculatorData> {
  private config: LenderSpreadSettings;
  private yearCount: number | undefined;
  private startingColumn: string;
  private startingColumnIndex: number;
  private rollupOnly: boolean;

  private generateNumberArray(n: number): number[] {
    const result: number[] = [];
    for (let i = 1; i <= n; i++) {
      result.push(i);
    }
    return result;
  }

  constructor(
    public underlying: DSCRCalculatorData,
    startingRow: number,
  ) {
    super(underlying);
    this.rowStart = startingRow;
    this.config = spreadConfig.lenderSettings[underlying.lenderId];
    this.yearCount = underlying.propertyColumnCount;
    this.startingColumn = underlying.startingColumn;
    this.startingColumnIndex = this.excelColToColIndex(this.startingColumn);
    this.rollupOnly = underlying.rollupOnly;
  }
  asColumns(): RawCellContent[][] {
    const filler = Array(this.underlying.propertyColumnCount).fill(" ");

    if (!this.rollupOnly) {
      console.log(`YEAR COUNT: ${this.yearCount}`);
      return [
        [
          DSCR_COLUMN_LABEL,
          ...this.generateNumberArray(this.yearCount ?? 1).map((index: number) => {
            const column = this.colNumberToExcelCol(this.startingColumnIndex + index);
            const debtServiceRowsCalculation = `(${this.underlying.debtServiceRows.map((row) => `${column}${row}`).join(" + ")})`;
            return `=IF(${debtServiceRowsCalculation} = 0, " - ", ${column}${this.underlying.noiRow} / ${debtServiceRowsCalculation})`;
          }),
        ],
        [
          TARGET_DSCR_COLUMN_LABEL,
          ...Array(this.yearCount).fill(this.config.defaultDSCR.toString()),
        ],
        [
          "",
          ...this.generateNumberArray(this.yearCount ?? 1).map((index) => {
            const column = this.colNumberToExcelCol(this.startingColumnIndex + index);
            return `=IF(${column}${this.rowStart} = " - ", " - ", IF(${column}${this.rowStart} >= ${column}${this.rowStart + 1}, "Pass", "Fail"))`;
          }),
        ],
      ];
    }

    const debtServiceRowsCalculation = `(${this.underlying.debtServiceRows.map((row) => `B${row}`).join(" + ")})`;
    return [
      [
        DSCR_COLUMN_LABEL,
        `=IF(${debtServiceRowsCalculation} = 0, " - ", B${this.underlying.noiRow} / ${debtServiceRowsCalculation})`,
        ...filler,
      ],
      [TARGET_DSCR_COLUMN_LABEL, this.config.defaultDSCR.toString(), ...filler],
      [
        "",
        `=IF(B${this.rowStart} = " - ", " - ", IF(B${this.rowStart} >= B${this.rowStart + 1}, "Pass", "Fail"))`,
        ...filler,
      ],
    ];
  }
  get highlightedRowLabels(): string[] {
    return [DSCR_COLUMN_LABEL, TARGET_DSCR_COLUMN_LABEL];
  }
  get percentageRowLabels(): string[] {
    return [];
  }
  get metadataRowLabels(): string[] {
    return [];
  }
}
