import { ScheduleK1Data } from "src/interfaces/FinancialFormData/ScheduleK1";
import { AutoRendered } from "src/classes/AutoRendered";
import { RawCellContent } from "hyperformula";
import { ScheduleK1 } from "@interfold-ai/shared/models/tax/schedules/ScheduleK1";
import { SpreadsDocumentType } from "@interfold-ai/shared/models/SpreadsDocumentType";
import { TaxFormYear } from "@interfold-ai/shared/models/tax/common";

export class ScheduleK1Rendered extends AutoRendered<ScheduleK1Data> {
  asColumns(): RawCellContent[][] {
    const data = this.underlying;
    if (data.summaries.length === 0) {
      return [];
    }

    const years = data.summaries.map((summary) => summary.year);
    const headers = [`${years[0]} K-1's`, `Distributions`, `Contributions`, `Net Total`];
    const body = data.summaries.map((summary) => [
      summary.businessName,
      summary.distributions ?? 0,
      summary.contributions ?? 0,
      "",
    ]);
    const distributionTotal = `=SUM(B2:B${this.scheduleK1s.length + 1})`;
    const contributionTotal = `=SUM(C2:C${this.scheduleK1s.length + 1})`;
    const total = `=B${this.scheduleK1s.length + 2}-C${this.scheduleK1s.length + 2}`;
    const footers = ["", distributionTotal, contributionTotal, total];

    return [headers, ...body, footers];
  }

  get highlightedRowLabels(): string[] {
    return [`${this.year} K-1's`];
  }

  get highlightedRowIndexes(): number[] {
    return [0, this.scheduleK1s.length + 1];
  }

  get percentageRowLabels() {
    return [];
  }

  metadataRowLabels: string[] = [];

  constructor(
    public scheduleK1s: ScheduleK1[],
    public year: TaxFormYear,
  ) {
    const lenderId = scheduleK1s.length > 0 ? scheduleK1s[0].lenderId : 0;
    const data: ScheduleK1Data = {
      form: "K1",
      summaries: [],
      year,
      entityName: "unused",
      lenderId,
    };

    scheduleK1s.forEach((schedule) => {
      data.summaries.push({
        year: schedule.year,
        businessName: schedule.businessName,
        distributions: schedule.distributions ?? 0,
        contributions: schedule.contributions ?? 0,
        documentType: SpreadsDocumentType.SCHEDULE_K1,
      });
    });

    super(data);
  }

  getEntityName(): string {
    return this.underlying.extractContext?.metadata?.entityName ?? "Entity";
  }
}
