import { ContextedBy } from "@interfold-ai/shared/models/extract/common";
import { RowWithType } from "../RowWithType";
import {
  AutoRenderedSheetBuilder,
  StartAt,
} from "src/classes/RenderedDocuments/AutoRenderedSheetBuilder";
import { Labels } from "./Labels";
import { RenderableBase } from "@interfold-ai/shared/models/render/common";
import { createAccessTrackingProxy } from "./createAccessTrackingProxy";
import { getValueByPath } from "./getValueByPath";
import { ReferenceFinders } from "./ReferenceFinders";
import { RenderableWithConfidence } from "src/classes/RenderedDocuments/Workflows/TaxFormWithConfidence";
import { HoverLabel } from "src/classes/RenderedDoc";

export type RawConfidenceContent = number | null | string;
type FormulaGetter<T extends RenderableBase> = (
  referenceFinders: ReferenceFinders,
  labels: Labels,
  data: T,
) => string;

export class AutoRenderedSheetBuilderWithConfidence<
  TForm extends RenderableBase,
  L extends Labels,
> extends AutoRenderedSheetBuilder<TForm, L> {
  confidenceBody: RawConfidenceContent[][] = [];
  confidenceData: ContextedBy<TForm, number>;

  constructor(
    data: TForm,
    confidenceData: ContextedBy<TForm, number>,
    labels: L,
    startingRow: number = 0,
    columnId: string = "B",
  ) {
    super(data, labels, startingRow, columnId);
    this.confidenceData = confidenceData;
  }

  static from<T extends RenderableBase, L extends Labels>(
    renderableWithConfidence: RenderableWithConfidence<T>,
    labels: L,
    startingRow: number = 0,
    columnId: string = "B",
  ): AutoRenderedSheetBuilderWithConfidence<T, L> {
    return new AutoRenderedSheetBuilderWithConfidence<T, L>(
      renderableWithConfidence.renderable,
      renderableWithConfidence.confidence,
      labels,
      startingRow,
      columnId,
    );
  }

  asConfidence(): RawConfidenceContent[][] {
    return this.confidenceBody;
  }

  asDocumentUploadIds(): (number | null)[][] {
    return this.documentUploadIds;
  }

  addEmptyRow(): AutoRenderedSheetBuilderWithConfidence<TForm, L> {
    const lastRow = this.body[this.body.length - 1];
    return this.addRowWithAutoConfidence(() =>
      Array.from({ length: lastRow.length ?? 0 }, () => ""),
    );
  }

  addHoverLabelForPrevRow(
    hoverLabel: HoverLabel,
  ): AutoRenderedSheetBuilderWithConfidence<TForm, L> {
    super.addHoverLabelForPrevRow(hoverLabel);
    return this;
  }

  setHoverInfoForLabel(
    label: string,
    hoverLabel: HoverLabel,
  ): AutoRenderedSheetBuilderWithConfidence<TForm, L> {
    super.setHoverInfoForLabel(label, hoverLabel);
    return this;
  }

  addRowFromData(
    labelKey: keyof L,
    valueGetter: (data: TForm) => any,
    options: {
      rowDataType?: "text" | "number";
      highlightStatus?: "highlighted" | "normal";
      fallbackValue?: any;
      documentUploadId?: number;
    } = {},
  ): AutoRenderedSheetBuilderWithConfidence<TForm, L> {
    const {
      rowDataType = "number",
      highlightStatus = "normal",
      fallbackValue,
      documentUploadId,
    } = options;
    return this.addRowWithAutoConfidence(
      ({ labels, resolve }) =>
        resolve((proxyData) => ({
          label: labels[labelKey],
          value: valueGetter(proxyData),
          fallbackValue,
          documentUploadId,
        })),
      rowDataType,
      highlightStatus,
    );
  }
  addRowWithStaticValue(
    labelKey: keyof L | string,
    staticValue: any,
    options: {
      rowDataType?: "text" | "number";
      highlightStatus?: "highlighted" | "normal";
    } = {},
  ): AutoRenderedSheetBuilderWithConfidence<TForm, L> {
    const { rowDataType = "number", highlightStatus = "normal" } = options;
    return this.addRowWithAutoConfidence(
      ({ labels, resolve }) =>
        resolve(() => ({
          label: labels[labelKey] ?? labelKey,
          value: staticValue,
        })),
      rowDataType,
      highlightStatus,
    );
  }

  propertyReferenceInColumn(property: string, startAt: StartAt = "beginning"): string {
    const label = this.labels[property];
    if (!label) {
      throw new Error(`Label for property ${property} not found.`);
    }
    const rowIndex = this.findRowIndex(label, startAt);
    return `${this.columnId}${rowIndex}`;
  }

  labelReferenceInColumn(label: string, startAt: StartAt = "beginning"): string {
    const rowIndex = this.body.findIndex((row) => row[0] === label);
    return `${this.columnId}${rowIndex + 1 + this.startingRow}`;
  }

  addRowWithFormula(
    labelKey: keyof L | string,
    formulaGetter: FormulaGetter<TForm>,
    options: {
      rowDataType?: "text" | "number";
      highlightStatus?: "highlighted" | "normal";
      confidenceOveride?: number[];
    } = {},
  ): AutoRenderedSheetBuilderWithConfidence<TForm, L> {
    const { rowDataType = "number", highlightStatus = "normal" } = options;
    return this.addRowWithAutoConfidence(
      ({ labels, columnReference, resolve }) =>
        resolve(() => ({
          label: labels[labelKey] ?? labelKey,
          formula: formulaGetter(
            {
              columnReference: columnReference,
              propertyReference: this.propertyReferenceInColumn.bind(this),
              labelReference: this.labelReferenceInColumn.bind(this),
              columnId: this.columnId,
              index: this.body.length,
            },
            labels,
            this.data,
          ),
        })),
      rowDataType,
      highlightStatus,
    );
  }
  addRowWithAutoConfidence(
    rowBuilder: RowBuilder<TForm, L>,
    rowDataType: "text" | "number" | undefined = undefined,
    highlightStatus: "highlighted" | "normal" = "normal",
  ): AutoRenderedSheetBuilderWithConfidence<TForm, L> {
    const boundReferenceInColumn = this.referenceInColumn.bind(this);

    const rowNumber = this.body.length + 1;
    const rowIndex = this.body.length;

    const resolve = (
      builder: (proxyData: TForm) => {
        label: string;
        value?: any;
        formula?: string;
        fallbackValue?: any;
        documentUploadId?: number;
      },
    ): RowWithType => {
      const accessPaths: string[] = [];
      const proxyData = createAccessTrackingProxy(this.data, accessPaths);

      const result = builder(proxyData);
      const { label, value, formula, fallbackValue, documentUploadId } = result;

      // Build the row
      const row: RowWithType = [label];
      if (value === undefined && formula === undefined && fallbackValue !== undefined) {
        row.push(fallbackValue);
      } else if ("value" in result) {
        row.push(value);
      } else if ("formula" in result) {
        row.push(formula);
      } else {
        row.push(null);
      }

      const confidenceValues = accessPaths.map((path) => {
        const dataValue = getValueByPath(this.data as any, path.split("."));
        const confidenceValue = getValueByPath(this.confidenceData as any, path.split("."));

        // If data value is undefined or null, default confidence to 1
        if (dataValue === undefined || dataValue === null) {
          return 1;
        }

        return typeof confidenceValue === "number" ? confidenceValue : 1;
      });
      const combinedConfidence = confidenceValues.length > 0 ? Math.min(...confidenceValues) : null;

      const confidences: (number | null)[] = [null, combinedConfidence];

      this.confidenceBody.push(confidences);

      if (documentUploadId) {
        this.documentUploadIds.push([null, documentUploadId]);
      } else {
        this.documentUploadIds.push([null, null]);
      }

      return row;
    };

    const row = rowBuilder({
      data: this.data,
      labels: this.labels,
      columnReference: boundReferenceInColumn,
      rowNumber,
      resolve,
    });
    row.rowDataType = rowDataType;

    this.body.push(row);

    if (highlightStatus === "highlighted") {
      this.highlightedRowIndexes = [...this.highlightedRowIndexes, rowIndex];
    }
    return this;
  }
}

/*
({ data, labels, columnReference, rowNumber, resolve, }: {
  data: TForm;
  labels: L;
  columnReference: (header: string, startAt?: StartAt) => string;
  rowNumber: number;
  resolve: (builder: (proxyData: TForm) => {
      label: string;
      value?: any;
      formula?: string;
  }) => RowWithType;
}) => RowWithType
 */

type RowBuilder<TForm extends RenderableBase, L extends Labels> = ({
  data,
  labels,
  columnReference,
  rowNumber,
  resolve,
}: BuiltRow<TForm, L>) => RowWithType;

interface BuiltRow<TForm, L extends Labels> {
  data: TForm;
  labels: L;
  columnReference: DynamicColumnReference;
  rowNumber: number;
  resolve: RowResolver;
}

type DynamicColumnReference = (header: string, startAt?: StartAt) => string;

type RowResolver = (
  builder: (proxyData: any) => {
    label: string;
    value?: any;
    formula?: string;
  },
) => RowWithType;
