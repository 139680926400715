import {
  FinancialItemComputed,
  FinancialItemType,
  CashFlowCandidates,
  FinancialStatement,
} from "@interfold-ai/shared/models/FinancialStatement";
import { GridRow } from "src/classes/GridState";
import { parseTaxCellAsFloatOrUndefined } from "@interfold-ai/shared/utils/parseTaxCellAsFloat";
import { colNumberToExcelCol } from "src/classes/RenderedDoc";
import { sanitizeTabName } from "./utils";
import { AutoRenderedSheetBuilder } from "./AutoRenderedSheetBuilder";
import { FinancialStatementCashFlowItems } from "@interfold-ai/shared/models/FinancialStatement";

const similarItem = (target: string, searchItem: string) => {
  return target.toLowerCase().includes(searchItem.toLowerCase());
};

const getCellReference = (colIndex: number, rowIndex: number): string => {
  const colLetter = colNumberToExcelCol(colIndex + 2);
  return `${colLetter}${rowIndex + 1}`;
};

/**
 * This function is used to adjust the interest values for the cash flow tab.
 * It is used to ensure that the interest values are properly signed for the cash flow tab.
 *
 * It only matters if expenses are recorded as negative numbers in the Financials tab.
 * Since we reference that tab for calculating the Cash Flow we need to adjust the sign
 * in some cases.
 *
 * @param candidates - The cash flow candidates.
 * @param rows - The rows of the cash flow tab.
 */
export const adjustInterestValuesForCashFlow = (
  candidates: CashFlowCandidates,
  rows: GridRow[],
) => {
  const interestRows = candidates.interest;
  const expenseTerms = ["expense", "expenses"];
  interestRows.forEach((interestRow) => {
    let processed = false;
    const description = interestRow.rowKey;
    let term = description.match(/\((.*)\)/)?.[1];

    // no term, we need to work backwards to find the root item for this interest row
    if (!term || term.trim() === "") {
      // work backwards to find the root item for this interest row
      for (let i = interestRow.rowIndex - 1; i >= 0; i--) {
        const row = rows[i];
        term = (row?.rowDataArray[0] as string)?.match(/\((.*)\)/)?.[1];
        // if we have a term or we reach the root element
        if (term || row?.rowMetadata?.levelIndex === 0) {
          break;
        }
      }
    }
    if (term && term.trim() !== "" && expenseTerms.includes(term.toLowerCase().trim())) {
      processed = true;
      const gridRow = rows.find((row) => row.rowDataArray[0] === description);
      if (gridRow) {
        gridRow.rowDataArray.slice(1).forEach((cell) => {
          if (cell) {
            // always reverse the sign
            // we want to add expenses (we want them to be positive number)
            // we want to subtract revenue/income (we want them to be negative numbers)
            interestRow.multipliers = interestRow.multipliers || [];
            interestRow.multipliers.push(-1);
          }
        });
      }
    }

    if (!processed) {
      const netIncomeRowCandidate = candidates.netIncome[0];
      const rowKey = netIncomeRowCandidate.rowKey.toLowerCase();
      const interestRowKey = interestRow.rowKey.toLowerCase();
      if (rowKey.includes("net income") || rowKey.includes("net loss")) {
        if (
          interestRowKey.includes("interest revenue") ||
          interestRowKey.includes("interest income")
        ) {
          const gridRow = rows.find((row) => row.rowDataArray[0] === description);
          if (gridRow) {
            gridRow.rowDataArray.slice(1).forEach((cell) => {
              if (cell) {
                // negate the interest row
                interestRow.multipliers = interestRow.multipliers || [];
                interestRow.multipliers.push(-1);
              }
            });
          }
        }
      }
    }
  });
};

export const cashFlowCandidateRowsFromItems = (
  rows: GridRow[],
  items: FinancialStatementCashFlowItems,
): CashFlowCandidates => {
  const usedRowIndices = new Set<number>();
  const getLastMatchingRow = (itemName: string) => {
    const matchingRows = rows.filter(
      (row) => row.rowDataArray[0] === itemName && !usedRowIndices.has(row.index),
    );
    //TODO: If we need to add more complex logic comparing and making sure certain rows are included, this is
    // where we would do it.
    const lastMatchingRow = matchingRows[matchingRows.length - 1];
    if (lastMatchingRow) {
      usedRowIndices.add(lastMatchingRow.index);
      return {
        rowIndex: lastMatchingRow.index,
        rowKey: lastMatchingRow.rowDataArray[0] as string,
      };
    }
    return null;
  };

  const netIncomeRows =
    items.netIncomeItems?.map(getLastMatchingRow).filter((row) => row !== null) || [];

  // looks like depreciation
  const depreciation =
    items.depreciationItems
      ?.map(getLastMatchingRow)
      .filter((row) => row !== null)
      .filter((row) => similarItem(row.rowKey, FinancialItemType.Depreciation)) || [];

  // looks like amortization
  const amortization =
    items.amortizationItems
      ?.map(getLastMatchingRow)
      .filter((row) => row !== null)
      .filter(
        (row) =>
          similarItem(row.rowKey, FinancialItemType.Amortization) && !depreciation.includes(row),
      ) || [];

  const interest =
    items.interestItems
      ?.map(getLastMatchingRow)
      .filter((row) => row !== null)
      .filter((row) => !depreciation.includes(row) && !amortization.includes(row)) || [];

  return {
    netIncome: netIncomeRows,
    depreciation,
    interest,
    amortization,
  };
};

export const buildCashFlowFromFinancialsTab = (
  candidateRows: CashFlowCandidates,
  allColumns: string[],
  sourceTabName: string,
) => {
  const sanitizedTabName = sanitizeTabName(sourceTabName);
  const rendered = new AutoRenderedSheetBuilder({}, {}, 0, "B", {
    supressContextMenu: false,
  });

  const columnCellRefs: Record<number, string[]> = {};
  allColumns.forEach((_, i) => (columnCellRefs[i] = []));

  rendered.addRow(() => ["", ...allColumns], "text", "highlighted");

  Object.entries(candidateRows).forEach(([_key, rows]) => {
    if (rows.length === 0) {
      return;
    }
    rows.forEach((row) => {
      const newRowIndex = rendered.body.length;
      rendered.addRow(() => {
        const rowData = [
          // refer to the same row key from the other sheet, passing -1 to account for the +2 logic in getCellReference
          `='${sanitizedTabName}'!${getCellReference(-1, row.rowIndex)}`,
          ...allColumns.map((_, i) => {
            const multiplier = row.multipliers?.[i];
            let multiplierFormula = "";
            if (multiplier) {
              multiplierFormula = ` * ${multiplier}`;
            } else {
              multiplierFormula = "";
            }
            const cellRef = `='${sanitizedTabName}'!${getCellReference(i, row.rowIndex)}${multiplierFormula}`;
            columnCellRefs[i].push(`${getCellReference(i, newRowIndex)}`);
            return cellRef;
          }),
        ];
        return rowData;
      });
    });
  });

  rendered.addRow(
    () => [
      FinancialItemComputed.BusinessCashFlowBeforeTax,
      ...allColumns.map((_, i) => {
        if (!columnCellRefs[i].length) {
          return "";
        }
        const firstCell = columnCellRefs[i][0];
        const lastCell = columnCellRefs[i][columnCellRefs[i].length - 1];
        return `=SUM(${firstCell}:${lastCell})`;
      }),
    ],
    "number",
    "highlighted",
  );

  return rendered;
};

export const convertToGridRows = (jsonData: FinancialStatement): GridRow[] => {
  let rowIndex = 0;
  const gridRows: GridRow[] = [];

  const allLarge = jsonData.items?.every((item) => item.s === "large");
  const allBold = jsonData.items?.every((item) => item.b);

  const columns = jsonData.availableColumns || [];
  gridRows.push({
    rowDataArray: [`${jsonData.timePeriod || ""}`, ...columns],
    rowDataType: "text",
    rowStyle: "highlighted",
    rowMetadata: {
      levelIndex: 0,
    },
    isManagedByApp: true,
    index: rowIndex++,
  });
  for (const item of jsonData.items || []) {
    const hasAtleastOneValue =
      item.v &&
      Object.keys(item.v).length > 0 &&
      Object.values(item.v).some((value) => value !== "" && value !== undefined && value !== null);
    const gridRow: GridRow = {
      rowDataArray: [
        item.n,
        ...columns.map((column) => item.v?.[column] || "").map(parseTaxCellAsFloatOrUndefined),
      ],
      rowDataType: hasAtleastOneValue ? "number" : "text",
      rowStyle:
        (!allBold && item.b) || (!allLarge && item.s === "large") ? "highlighted" : "standard",
      rowMetadata: {
        levelIndex: item.l,
      },
      isManagedByApp: true,
      index: rowIndex++,
    };
    gridRows.push(gridRow);
  }

  return gridRows;
};
