import { CanonicalRentRoll } from "@interfold-ai/shared/models/Property";
import { spreadablePropertyLabel } from "src/redux/selectors/spreadablePropertyLabel";
import { RentRollTableData } from "@interfold-ai/shared/models/Property";

export const canonicalRentRoll2RentRollData = (
  canonicalRentRoll: CanonicalRentRoll,
): RentRollTableData => {
  const rentRoll: RentRollTableData = {
    repairs: canonicalRentRoll.repairs,
    managementFeePercentage: canonicalRentRoll.managementFeePercentage,
    replacementCostPercentage: null,
    grossRents: canonicalRentRoll.monthlyGrossRents * 12,
    annualPropertyTaxes: canonicalRentRoll.annualPropertyTaxes ?? null,
    annualInsurance: canonicalRentRoll.annualInsurance ?? null,
    utilities: canonicalRentRoll.utilities ?? null,
    occupancyPercentage: null,
    extractContext: canonicalRentRoll.extractContext,
    propertyName: spreadablePropertyLabel(canonicalRentRoll),
    year: canonicalRentRoll.extractContext.metadata.year,
    documentUploadId: canonicalRentRoll.extractContext.metadata.documentUploadId ?? null,
  };

  return rentRoll;
};
