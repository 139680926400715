import { FinancialStatement } from "@interfold-ai/shared/models/FinancialStatement";
import { Rendered } from "src/classes/RenderedDoc";
import { GridState } from "../GridState";
import { ColDef } from "ag-grid-community";
import { convertToGridRows } from "src/classes/RenderedDocuments/financial-statement-utils";

export class FinancialStatementItemsRenderedDoc extends Rendered<FinancialStatement, string> {
  constructor(public items: FinancialStatement) {
    super(items);
    this.gridOptions = {
      supressContextMenu: false,
    };
  }

  get numberOfColumns(): number {
    return (this.items.availableColumns?.length || 0) + 1;
  }

  get defaultRowLabels(): string[] {
    return Object.values(this.initialGridState).map((row) => row.rowDataArray[0]);
  }

  get highlightedRowLabels(): string[] {
    return Object.values(this.initialGridState)
      .filter((row) => row.row === "highlighted")
      .map((row) => row.rowDataArray[0]);
  }

  get percentageRowLabels(): string[] {
    return [];
  }

  metadataRowLabels: string[] = [];

  get columnDefs(): ColDef[] {
    return Array.from({ length: this.numberOfColumns }, (_, index) => {
      const letter = this.colNumberToExcelCol(index + 1);
      return {
        headerName: letter,
        colId: letter,
        field: letter,
        sortable: false,
        flex: 1,
      };
    });
  }

  get initialGridState(): GridState {
    const rows = convertToGridRows(this.items);
    return Object.fromEntries(
      rows.map((row, index) => {
        return [`row-${index}`, row];
      }),
    );
  }
}
