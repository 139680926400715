import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  AddRow,
  FetchFromDb,
  GenerateSheets,
  LoadFromId,
  RemoveRow,
  SaveToDb,
  SetColSpans,
  SetColumnDefs,
  SetConfidenceData,
  SetCurrentSpreadId,
  SetDocumentUploadIds,
  SetGridOptions,
  SetGridStates,
  SetHoverInfos,
  SetJointlyOwnedAssets,
  SetPropertyGroups,
  SetSelectedAssets,
  SetSpreadUserState,
  SetTabGroups,
  SpreadError,
  UpdateCell,
} from "../actions/spread.action";
import { SpreadAction } from "../actions/actions.constants";
import { toastService } from "src/services/ToastService";
import {
  colSpansSelector,
  columnsDefsSelector,
  confidenceSelector,
  currentSpreadIdSelector,
  documentUploadIdsSelector,
  gridOptionsSelector,
  gridStatesSelector,
  hoverInfoSelector,
  jointlyOwnedAssetsSelector,
  propertyGroupsSelector,
  selectedAssetsSelector,
  tabGroupsSelector,
} from "../selectors/spread.selector";
import {
  gridStateApiService,
  GridStateInitParams,
} from "src/services/api-services/FinancialSheetApiService";
import { JointlyOwnedAssets, PropertyGroups, TabName } from "src/redux/reducers/types";
import { GridState } from "src/classes/GridState";
import { FinancialSheet } from "@interfold-ai/db";
import { loanDetailSelector, managerEmployerIdSelector } from "../selectors/loan.selector";
import { LoanDetails } from "src/models/Loan";
import { ColDef } from "ag-grid-community";
import { SPREAD_USER_STATE } from "../reducers/spread.reducer";
import { TabGroup } from "src/classes/RenderedDocuments/helpers";
import { HoverInfo, RenderedDocOptions } from "src/classes/RenderedDoc";
import { RawConfidenceContent } from "src/classes/RenderedDocuments/AutoRenderedSheetBuilderWithConfidence";

export function* calculateSpread(): Generator {
  const lenderEmployeeId = yield select(managerEmployerIdSelector);
  const _loanDetails = yield select(loanDetailSelector);
  const loanDetails = _loanDetails as LoanDetails;
  const id = parseInt(loanDetails.id.toString());
  const isAnnualReview = loanDetails.investment_type === "annual_reviews";
  const loanOrAnnualReview = isAnnualReview
    ? {
        AnnualReview: {
          connect: {
            id: id,
          },
        },
      }
    : {
        Loan: {
          connect: {
            id: id,
          },
        },
      };
  const input = {
    slug: new Date().toISOString(),
    lenderEmployeeId: lenderEmployeeId as number,
    ...loanOrAnnualReview,
  };

  const _existingSpreadId = yield select(currentSpreadIdSelector);
  const existingSpreadId = _existingSpreadId as number | null;
  if (!existingSpreadId) {
    try {
      const _financialSheet = yield call(gridStateApiService.init, input);
      const financialSheet = _financialSheet as FinancialSheet | Error;
      if (financialSheet instanceof Error) {
        yield put(SpreadError(financialSheet));
        return;
      }
      yield put(SetCurrentSpreadId(financialSheet.id));
    } catch (e) {
      const error = e as Error;
      yield put(SpreadError(error));
      return;
    }
  }

  yield put(GenerateSheets(true));
}

export function* spreadError(action: ReturnType<typeof SpreadError>): Generator {
  toastService.showError(action.payload.message);
}

export function* handleGridChange(
  action: ReturnType<typeof AddRow | typeof RemoveRow | typeof UpdateCell>,
): Generator {
  yield put(GenerateSheets(true));
}

export function* saveToDb(): Generator {
  const _gridStates = yield select(gridStatesSelector);
  const _gridOptions = yield select(gridOptionsSelector);
  const _curent_spread_id = yield select(currentSpreadIdSelector);
  const _column_defs = yield select(columnsDefsSelector);
  const _tabGroups = yield select(tabGroupsSelector);
  const hoverInfos = (yield select(hoverInfoSelector)) as Record<TabName, HoverInfo[][]>;
  const confidenceData = (yield select(confidenceSelector)) as Record<
    TabName,
    RawConfidenceContent[][]
  >;
  const documentUploadIds = yield select(documentUploadIdsSelector);
  const _propertyGroups = yield select(propertyGroupsSelector);
  const propertyGroups = _propertyGroups as PropertyGroups;
  const _selectedAssets = yield select(selectedAssetsSelector);
  const selectedAssets = _selectedAssets as string[];
  const _colSpans = yield select(colSpansSelector);
  const colSpans = _colSpans as Record<TabName, Record<string, number>>;
  const jointlyOwnedAssets: JointlyOwnedAssets[] = (yield select(jointlyOwnedAssetsSelector) ??
    []) as JointlyOwnedAssets[];
  const columnDefs = _column_defs as Record<TabName, ColDef>;
  const currentSpreadId = _curent_spread_id as number | null;
  if (!currentSpreadId) {
    toastService.showError("No current spread id");
    return;
  }
  const gridStates = _gridStates as Record<TabName, GridState>;
  const gridOptions = _gridOptions as Record<TabName, RenderedDocOptions>;
  const tabGroups = _tabGroups as TabGroup[];

  const loanDetails = yield select(loanDetailSelector);
  const loanId =
    loanDetails.investment_type === "annual_reviews" ? null : loanDetails.id.toString();
  const annualReviewId =
    loanDetails.investment_type === "annual_reviews" ? loanDetails.id.toString() : null;
  const _results = yield call(gridStateApiService.saveToDb, {
    id: currentSpreadId,
    gridStates,
    gridOptions,
    columnDefs,
    tabGroups,
    hoverInfos,
    confidenceData,
    documentUploadIds,
    propertyGroups,
    selectedAssets,
    colSpans,
    jointlyOwnedAssets,
    loanId,
    annualReviewId,
  });
  const results = _results as FinancialSheet | Error;
  if (results instanceof Error) {
    yield put(SpreadError(results));
    return;
  }
  yield put(SetCurrentSpreadId(results.id));
  toastService.showSuccess("Data saved to database");
}

export function* fetchFromDb(action: ReturnType<typeof FetchFromDb>): Generator {
  const _financialSheet = yield call(
    gridStateApiService.fetchByAnnualReviewOrLoanId,
    action.payload,
  );
  const financialSheet = _financialSheet as FinancialSheet[] | Error;
  if (financialSheet instanceof Error) {
    yield put(SpreadError(financialSheet));
    return;
  }
  if (financialSheet.length === 0) {
    yield put(SetSpreadUserState(SPREAD_USER_STATE.CHOOSING_FILES));
    return;
  }
  yield put(SetCurrentSpreadId(financialSheet[0].id));

  const sheet = financialSheet[0];
  if (!sheet.gridState || Object.keys(sheet.gridState).length === 0) {
    return;
  }

  const gridStates = Object.entries(sheet.gridState).reduce(
    (acc, [tabName, gridState]) => {
      if (typeof gridState === "string") {
        const parsedGridState = JSON.parse(gridState);
        acc[tabName as TabName] = parsedGridState;
      } else if (typeof gridState === "object") {
        acc[tabName as TabName] = gridState;
      }
      return acc;
    },
    {} as Record<TabName, GridState>,
  );

  yield put(SetTabGroups(sheet.tabGroups as TabGroup[]));
  yield put(SetHoverInfos(sheet.hoverInfos as unknown as Record<TabName, HoverInfo[][]>));
  yield put(SetGridStates(gridStates));
  yield put(SetGridOptions(sheet.gridOptions as unknown as Record<TabName, RenderedDocOptions>));
  yield put(SetColumnDefs(sheet.columnDefs as unknown as Record<TabName, ColDef>));
  yield put(GenerateSheets(false));
  yield put(SetSpreadUserState(SPREAD_USER_STATE.SPREADSHEET));
  yield put(
    SetConfidenceData(sheet.confidences as unknown as Record<TabName, RawConfidenceContent[][]>),
  );
  yield put(
    SetDocumentUploadIds(
      sheet.documentUploadIds as unknown as Record<TabName, (number | null)[][]>,
    ),
  );
  yield put(SetPropertyGroups(sheet.propertyGroups as unknown as PropertyGroups));
  yield put(SetSelectedAssets(sheet.selectedAssets as string[]));
  yield put(SetColSpans(sheet.colSpans as Record<TabName, Record<string, number>>));
  yield put(SetJointlyOwnedAssets(sheet.jointlyOwnedAssets as JointlyOwnedAssets[]));
}

export function* generateSheets(action: ReturnType<typeof GenerateSheets>): Generator {
  if (action.payload) {
    yield put(SaveToDb());
  }
}

export function* loadFromId(action: ReturnType<typeof LoadFromId>): Generator {
  const _financialSheet = yield call(gridStateApiService.fetchById, action.payload);
  const financialSheet = _financialSheet as FinancialSheet | Error;
  if (financialSheet instanceof Error) {
    yield put(SpreadError(financialSheet));
    return;
  }
  if (!financialSheet.gridState || Object.keys(financialSheet.gridState).length === 0) {
    return;
  }

  yield put(SetCurrentSpreadId(financialSheet.id));

  yield put(SetGridStates(financialSheet.gridState as unknown as Record<TabName, GridState>));
  yield put(SetColumnDefs(financialSheet.columnDefs as unknown as Record<TabName, ColDef>));
  yield put(GenerateSheets(false));
}

export function* loadManualWorkflow(): Generator {
  toastService.showSuccess("Manual workflow started");
  const lenderEmployeeId = yield select(managerEmployerIdSelector);
  const _loanDetails = yield select(loanDetailSelector);
  const loanDetails = _loanDetails as LoanDetails;
  const id = parseInt(loanDetails.id.toString());
  const isAnnualReview = loanDetails.investment_type === "annual_reviews";
  const _colDefs = yield select(columnsDefsSelector);
  const colDefs = _colDefs as Record<TabName, ColDef>;
  const _gridStates = yield select(gridStatesSelector);
  const gridStates = _gridStates as Record<TabName, GridState>;
  const loanOrAnnualReview = isAnnualReview
    ? {
        AnnualReview: {
          connect: {
            id: id,
          },
        },
      }
    : {
        Loan: {
          connect: {
            id: id,
          },
        },
      };
  const input: GridStateInitParams = {
    slug: new Date().toISOString(),
    lenderEmployeeId: lenderEmployeeId as number,
    ...loanOrAnnualReview,
    columnDefs: colDefs,
    gridState: gridStates,
  };

  const _financialSheet = yield call(gridStateApiService.init, input);
  const financialSheet = _financialSheet as FinancialSheet | Error;
  if (financialSheet instanceof Error) {
    yield put(SpreadError(financialSheet));
    return;
  }
  yield put(SetCurrentSpreadId(financialSheet.id));
  yield put(GenerateSheets(false));
}

export function* spreadSaga(): Generator {
  yield all([
    takeLatest(SpreadAction.CALCULATE_SPREAD, calculateSpread),
    takeLatest(SpreadAction.SPREAD_ERROR, spreadError),
    takeLatest(SpreadAction.ADD_ROW, handleGridChange),
    takeLatest(SpreadAction.REMOVE_ROW, handleGridChange),
    takeLatest(SpreadAction.UPDATE_CELL, handleGridChange),
    takeLatest(SpreadAction.SAVE_TO_DB, saveToDb),
    takeLatest(SpreadAction.FETCH_FROM_DB, fetchFromDb),
    takeLatest(SpreadAction.GENERATE_SHEETS, generateSheets),
    takeLatest(SpreadAction.LOAD_MANUAL_WORKFLOW, loadManualWorkflow),
  ]);
}

export default spreadSaga;
