import Axios, {
  AxiosError,
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
  ParamsSerializerOptions,
  RawAxiosRequestHeaders,
  ResponseType,
} from "axios";
import { StatusCodes } from "src/constants/status-codes";
import { BaseError, ErrorStatusCode, ValidationErrorType } from "src/classes/BaseError";
import { toastService } from "src/services/ToastService";
import { localStorageService } from "src/services/LocalStorageService";

interface RequestConfig<TBody> extends AxiosRequestConfig<TBody> {
  requestId?: string;
  redirectIfUnauthorized?: boolean;
}

export declare type QueryParams = {
  [key: string]: string | string[];
};

export class BaseApiError<T = any> extends BaseError<T> {
  constructor(
    message: string,
    status: ErrorStatusCode,
    response?: any,
    errors: ValidationErrorType[] = [],
    data: any = [],
  ) {
    super(message, status, response, errors, data);
  }

  static fromJSON<T = any>(axiosError: AxiosError<T>): BaseApiError<T | never> {
    return new BaseApiError<never>(
      axiosError.message,
      axiosError.response?.status as ErrorStatusCode,
      axiosError.response?.data,
    );
  }
}

export class BaseApiService {
  private static instance: BaseApiService;

  private requestMap = new Map<string, CancelTokenSource>();

  private defaultHeaders = {
    "Content-Type": "application/json",
    "Accept-Encoding": "*",
  };

  public static getInstance(): BaseApiService {
    if (!this.instance) {
      this.instance = new BaseApiService();
    }
    return this.instance;
  }

  public get<TRes = any>(
    url: string,
    opts?: {
      params?: QueryParams;
      headers?: Partial<AxiosHeaders>;
      useDefaultHeaders?: boolean;
      extras?: {
        requestId?: string;
        useAuth?: boolean;
        authToken?: string;
        paramsSerializer?: ParamsSerializerOptions;
        responseType?: ResponseType;
      };
    },
  ): Promise<TRes> {
    const headers = opts?.useDefaultHeaders
      ? new AxiosHeaders(this.defaultHeaders)
      : new AxiosHeaders(opts?.headers);
    return this.request<never, TRes>(
      {
        method: "GET",
        url,
        headers,
        params: opts?.params,
        requestId: opts?.extras?.requestId,
        paramsSerializer: opts?.extras?.paramsSerializer,
        ...(opts?.extras?.responseType ? { responseType: opts.extras.responseType } : {}),
      },
      opts?.extras?.useAuth,
      opts?.extras?.authToken,
    );
  }

  public delete<TRes = any>(
    url: string,
    opts?: {
      params?: QueryParams;
      headers?: Partial<AxiosRequestHeaders>;
      useDefaultHeaders?: boolean;
      extras?: {
        requestId?: string;
        useAuth?: boolean;
      };
    },
  ) {
    return this.request<never, TRes>(
      {
        method: "DELETE",
        url,
        headers: opts?.useDefaultHeaders
          ? new AxiosHeaders(this.defaultHeaders as Partial<AxiosRequestHeaders>)
          : new AxiosHeaders(opts?.headers),
        params: opts?.params,
        requestId: opts?.extras?.requestId,
      },
      opts?.extras?.useAuth,
    );
  }

  public post<TBody = any, TRes = any>(
    url: string,
    data?: TBody,
    opts?: {
      headers?: Partial<AxiosRequestHeaders>;
      useDefaultHeaders?: boolean;
      params?: QueryParams;
      extras?: {
        requestId?: string;
        useAuth?: boolean;
        authToken?: string;
      };
      onUploadProgress?: any;
    },
  ): Promise<TRes> {
    return this.request<TBody, TRes>(
      {
        method: "POST",
        url,
        data,
        headers: opts?.useDefaultHeaders
          ? new AxiosHeaders(this.defaultHeaders as Partial<AxiosRequestHeaders>)
          : new AxiosHeaders(opts?.headers),
        params: opts?.params,
        requestId: opts?.extras?.requestId,
        onUploadProgress: opts?.onUploadProgress,
      },
      opts?.extras?.useAuth,
      opts?.extras?.authToken,
    );
  }

  public put<TBody = any, TRes = any>(
    url: string,
    data?: TBody,
    opts?: {
      headers?: Partial<AxiosRequestHeaders>;
      useDefaultHeaders?: boolean;
      params?: QueryParams;
      extras?: {
        requestId?: string;
        useAuth?: boolean;
        authToken?: string;
      };
    },
  ) {
    return this.request<TBody, TRes>(
      {
        method: "PUT",
        url,
        data,
        headers: opts?.useDefaultHeaders
          ? new AxiosHeaders(this.defaultHeaders as Partial<AxiosRequestHeaders>)
          : new AxiosHeaders(opts?.headers),
        params: opts?.params,
        requestId: opts?.extras?.requestId,
      },
      opts?.extras?.useAuth,
      opts?.extras?.authToken,
    );
  }

  public patch<TBody = any, TRes = any>(
    url: string,
    data?: any,
    opts?: {
      headers?: Partial<AxiosRequestHeaders>;
      useDefaultHeaders?: boolean;
      params?: QueryParams;
      extras?: {
        requestId?: string;
        useAuth: boolean;
      };
    },
  ) {
    return this.request<TBody, TRes>(
      {
        method: "PATCH",
        url,
        data,
        headers: opts?.useDefaultHeaders
          ? new AxiosHeaders(this.defaultHeaders as Partial<AxiosRequestHeaders>)
          : new AxiosHeaders(opts?.headers),
        params: opts?.params,
        requestId: opts?.extras?.requestId,
      },
      opts?.extras?.useAuth,
    );
  }

  generateHeaders = async (
    headers?: RawAxiosRequestHeaders | AxiosHeaders,
    useAuth?: boolean,
    authToken?: string,
  ) => {
    let defaultHeaders = {};

    if (useAuth) {
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${authToken}`,
      };
    }

    if (!headers) {
      return defaultHeaders;
    }
    return {
      ...defaultHeaders,
      ...headers,
    };
  };

  private async request<TBody, TRes>(
    config: RequestConfig<TBody>,
    useAuth?: boolean,
    authToken?: string,
  ): Promise<TRes> {
    const cancelToken = this.addToRequestMap(config.requestId);
    try {
      const response = await Axios.request<TRes, AxiosResponse<TRes>, TBody>({
        cancelToken,
        ...config,
        headers: await this.generateHeaders(config.headers, useAuth ?? true, authToken),
      });
      this.removeFromRequestMap(config.requestId);
      return response?.data;
    } catch (error: any) {
      const { response } = error as AxiosError;
      const responseAsAny = response as any;
      const dataAsError = responseAsAny?.data;
      // If we receive a 401 status code, redirect the user to a login page
      if (error.response && error.response?.status === StatusCodes.UNAUTHORIZED) {
        const userType = localStorageService.getValue("userType");
        if (userType !== "undefined") {
          localStorageService.deleteValue("userType");
          localStorageService.deleteValue("lenderId");
        }
      } else if (dataAsError?.message) {
        toastService.showError(dataAsError.message);
      } else if (error.response && error.response.data) {
        console.log(error.response.data);
      } else {
        console.log(error);
        toastService.showError(error?.message);
      }
      throw BaseApiError.fromJSON(error);
    }
  }

  private addToRequestMap(requestId?: string): CancelToken | undefined {
    if (!requestId) {
      return undefined;
    }

    const source = Axios.CancelToken.source();
    this.requestMap.set(requestId, source);
    return source.token;
  }

  private removeFromRequestMap(requestId?: string) {
    if (!requestId) {
      return;
    }

    this.requestMap.delete(requestId);
  }
}

export const baseApiService = BaseApiService.getInstance();
