import { AppraisalReport } from "@interfold-ai/shared/models/appraisal";
import { GridRow } from "../GridState";

const APPRAISAL_FIELD_DISPLAY_NAMES: Record<keyof AppraisalReport, string> = {
  // Report Basics
  effectiveDate: "Effective Date",
  appraisalClient: "Appraisal Client",
  lenderName: "Lender Name",
  lenderAddress: "Lender Address",
  borrowerName: "Borrower Name",
  ownerOfRecord: "Owner of Public Record",

  // Property Identification
  subjectPropertyAddress: "Subject Property Address",
  propertyAppraisedAddress: "Appraised Property Address",
  numberOfUnits: "Number of Units",

  // Property Characteristics
  grossLivingArea: "Gross Living Area",
  landArea: "Land Area",
  numberOfTotalRoomsBedroomsAndBathrooms: "Total/Bed/Bath Rooms",
  qualityOfConstruction: "Quality of Construction",
  conditionOfProperty: "Property Condition",
  isRepairOrInspectionNeeded: "Repairs Needed",
  maintenanceFee: "Maintenance Fee (PUD, HOA, etc.)",
  occupant: "Occupant",

  // Neighborhood & Market Analysis
  isRuralNeighborhood: "Rural Neighborhood",
  propertyValueTrend: "Property Value Trend",
  isListedForSaleInLast12Months: "Listed for Sale in Last 12 Months",
  marketRent: "Market Rent",

  // Zoning & Hazards
  zoningClassification: "Zoning Classification",
  zoningCompliance: "Zoning Compliance",
  isFloodHazardArea: "Flood Hazard Area",

  // Comparables Analysis
  compareSalesSchedules: "Comparable Sales",
  comparableRentSchedules: "Comparable Rentals",

  // Value & Cost
  appraisedValue: "Appraised Value",
  isAppraisalValueMadeAsIs: "Appraisal As-Is",
  newTotalReplacementCost: "New Replacement Cost",

  // Appraiser Information
  appraiserName: "Appraiser Name",
  appraiserLicenseDetails: "Appraiser License Details",
  appraiserInsuranceDetails: "Appraiser Insurance Details",

  // Supporting Documentation
  arePropertyPicturesAvailable: "Property Pictures Available",
  isPropertySketchAvailable: "Property Sketch Available",
  extraordinaryAssumptions: "Extraordinary Assumptions",
};

type AppraisalArrayOfStringFields = "comparableRentSchedules" | "compareSalesSchedules";

const isArrayField = (
  key: keyof Required<AppraisalReport>,
): key is AppraisalArrayOfStringFields => {
  return key === "comparableRentSchedules" || key === "compareSalesSchedules";
};

export function convertToGridRows(report: AppraisalReport): GridRow[] {
  const gridRows: GridRow[] = [];
  let index = 0;

  gridRows.push({
    index: index++,
    rowDataArray: ["Field", "Value"],
    rowDataType: "text",
    rowStyle: "highlighted",
    isManagedByApp: true,
  });

  // Use the order from APPRAISAL_FIELD_DISPLAY_NAMES
  Object.keys(APPRAISAL_FIELD_DISPLAY_NAMES).forEach((key) => {
    const value = report[key as keyof AppraisalReport];
    const displayName = APPRAISAL_FIELD_DISPLAY_NAMES[key as keyof Required<AppraisalReport>];

    if (Array.isArray(value)) {
      if (isArrayField(key as keyof AppraisalReport)) {
        gridRows.push({
          index: index++,
          rowDataArray: [displayName, ""],
          rowDataType: "text",
          rowStyle: "highlighted",
          isManagedByApp: true,
          rowMetadata: {
            levelIndex: 0,
          },
        });

        value.forEach((item, idx) => {
          const prefix = key === "comparableRentSchedules" ? "Rental" : "Sale";
          gridRows.push({
            index: index++,
            rowDataArray: [`${prefix} ${idx + 1}`, item],
            rowDataType: "text",
            rowStyle: "standard",
            isManagedByApp: true,
            rowMetadata: {
              levelIndex: 1,
            },
          });
        });
      }
    } else {
      gridRows.push({
        index: index++,
        rowDataArray: [displayName, value || ""],
        rowDataType: "text",
        rowStyle: "standard",
        isManagedByApp: true,
        rowMetadata: {
          levelIndex: 0,
        },
      });
    }
  });

  return gridRows;
}
