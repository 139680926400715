import { TaxFormYear } from "@interfold-ai/shared/models/tax/common";
import { GlobalTaxReturnGroups } from "./Workflows/groupTaxReturnsForGlobalTotals";
import { AutoRenderedSheetBuilder } from "./AutoRenderedSheetBuilder";
import { colNumberToExcelCol } from "../RenderedDoc";
import { NOIAnalysisType } from "./Workflows/RenderedNOIAnalysis";
import { ManyLoanCalculatorsRendered } from "./LoanCalculatorRendered";
import { ManyLoanCalculatorsData } from "./LoanCalculatorRendered";
import { DSCRCalculatorRendered } from "./DSCRCalculatorRendered";
import { DSCRCalculatorData } from "./DSCRCalculatorRendered";
import { SupportedLenderId } from "@interfold-ai/shared/models/SpreadsConfig";
import { Labels } from "./Labels";
import { spreadConfig } from "@interfold-ai/shared/spreads-config";

const NOITaxReturnGlobalTotalHeadings: Labels = {
  Source: "Source",
  Entity: "Entity",
  Property: "Property",
  Year: "Year",
  GrossRents: "Gross Rents",
  TotalExpenses: "Total Expenses",
  NetIncome: "Net Income",
  Interest: "Interest",
  Depreciation: "Depreciation",
  Amortization: "Amortization",
  NetOperatingIncome: "Net Operating Income (NOI)",
  DebtServiceIndividual: "Debt Service (Individual)",
  ExistingDebt: "Existing Debt",
  NewDebt: "New Debt",
  TotalDebtService: "Total Debt Service",
  ExcessDeficit: "Excess/Deficit",
  DebtServiceCoverageRatio: "Debt Service Coverage Ratio",
  TargetDSCR: "Target DSCR",
  LoanAmount: "Loan Amount",
  DebtServiceShared: "Debt Service (Shared)",
  ExcessCashFlow: "Excess Cash Flow",
};

export type NOITaxReturnGlobalTotalTable = AutoRenderedSheetBuilder<
  GlobalTaxReturnGroups,
  typeof NOITaxReturnGlobalTotalHeadings
>;

export function buildNOITaxReturnGlobalTotalTable(
  data: GlobalTaxReturnGroups,
  startingColumn: string,
  startingRow: number,
  noiAnalysisType: NOIAnalysisType,
  lenderId: SupportedLenderId,
): NOITaxReturnGlobalTotalTable {
  const builder = new AutoRenderedSheetBuilder(
    data,
    NOITaxReturnGlobalTotalHeadings,
    startingRow,
    startingColumn,
  );

  builder.highlightedRowLabels = [
    { Source: NOITaxReturnGlobalTotalHeadings.Source },
    { Entity: NOITaxReturnGlobalTotalHeadings.Entity },
    { Property: NOITaxReturnGlobalTotalHeadings.Property },
    { Year: NOITaxReturnGlobalTotalHeadings.Year },
    { NetOperatingIncome: NOITaxReturnGlobalTotalHeadings.NetOperatingIncome },
    { TotalDebtService: NOITaxReturnGlobalTotalHeadings.TotalDebtService },
    { ExcessDeficit: NOITaxReturnGlobalTotalHeadings.ExcessDeficit },
    { DebtServiceCoverageRatio: NOITaxReturnGlobalTotalHeadings.DebtServiceCoverageRatio },
    { TargetDSCR: NOITaxReturnGlobalTotalHeadings.TargetDSCR },
    { LoanAmount: NOITaxReturnGlobalTotalHeadings.LoanAmount },
  ];

  const years = Array.from(new Set(Object.keys(data).map((year) => year as TaxFormYear))).sort();
  const repeatingColumns = (text: string | number) => {
    return years.map(() => text);
  };
  const yearsRow = () => {
    return years.map((year) => year);
  };
  const sumOfAllProperties = (rowNumber: number) => {
    const cols = [];
    for (const year of years) {
      const yearData = data[year];
      const formula = yearData.map((yd) => `'${yd.tabName}'!${yd.column}${rowNumber}`).join("+");
      cols.push(`=${formula}`);
    }
    return cols;
  };
  builder
    .addRow(
      ({ labels }) => [labels.Source, ...repeatingColumns("All Tax Returns")],
      "text",
      "highlighted",
    )
    .addRow(({ labels }) => [labels.Entity, ...repeatingColumns("Total")])
    .addRow(({ labels }) => [labels.Year, ...yearsRow()], "text")
    .addRow(({ labels }) => [labels.Property, ...repeatingColumns("All Properties")])
    .addRow(({ labels, rowNumber }) => [labels.GrossRents, ...sumOfAllProperties(rowNumber)])
    .addRow(({ labels, rowNumber }) => [labels.TotalExpenses, ...sumOfAllProperties(rowNumber)])
    .addRow(({ labels, rowNumber }) => [labels.Interest, ...sumOfAllProperties(rowNumber)])
    .addRow(({ labels, rowNumber }) => [labels.Depreciation, ...sumOfAllProperties(rowNumber)])
    .addRow(({ labels, rowNumber }) => [labels.Amortization, ...sumOfAllProperties(rowNumber)])
    .addRow(({ labels, rowNumber }) => [
      labels.NetOperatingIncome,
      ...sumOfAllProperties(rowNumber),
    ])
    .addRow(({ labels }) => [labels.ExistingDebt, ...repeatingColumns(0)])
    .addRow(({ labels, rowNumber }) => [
      labels.NewDebt,
      ...(noiAnalysisType === NOIAnalysisType.DEAL_SIZING
        ? years.map(
            (_year, index) =>
              // Net Operating Income (line 17) / Target DSCR (line 15) - Existing Debt (line 18)
              `=${colNumberToExcelCol(index + 2)}${rowNumber - 2} / ${colNumberToExcelCol(index + 2)}${rowNumber + 2} - ${colNumberToExcelCol(index + 2)}${rowNumber - 1}`,
          )
        : repeatingColumns("0")),
    ])
    .addRow(({ labels, rowNumber }) => [
      labels.TotalDebtService,
      /// add the existing debt and new debt for each year/column
      ...years.map(
        (_year, index) =>
          `=SUM(${colNumberToExcelCol(index + 2)}${rowNumber - 2}:${colNumberToExcelCol(index + 2)}${rowNumber - 1})`,
      ),
    ]);
  const rowModifier = noiAnalysisType === NOIAnalysisType.DEAL_SIZING ? 1 : 0;
  if (noiAnalysisType === NOIAnalysisType.DEAL_SIZING) {
    builder.addRow(({ labels }) => [
      labels.TargetDSCR,
      ...repeatingColumns(spreadConfig.lenderSettings[lenderId].defaultDSCR),
    ]);
  }
  builder.addRow(({ labels, rowNumber }) => [
    labels.ExcessDeficit,
    ...years.map(
      (_year, index) =>
        `=${colNumberToExcelCol(index + 2)}${rowNumber - 4 - rowModifier}-${colNumberToExcelCol(index + 2)}${rowNumber - 1 - rowModifier}`,
    ),
  ]);

  if (noiAnalysisType === NOIAnalysisType.DEAL_SIZING) {
    const excessDeficitIndex = 15;
    const yearToCol = years.reduce(
      (acc, year, i) => {
        acc[year] = colNumberToExcelCol(i + 2);
        return acc;
      },
      {} as Record<TaxFormYear, string>,
    );

    const excessDeficitRefs: string[] = years.map(
      (year) => `${yearToCol[year]}${excessDeficitIndex}`,
    );
    const newDebtRefs: string[] = years.map((year) => `${yearToCol[year]}12`);
    const manyLoanData: ManyLoanCalculatorsData = {
      excessCashflows: excessDeficitRefs,
      lenderId: lenderId,
      newDebt: newDebtRefs,
    };
    const loanCalculators = new ManyLoanCalculatorsRendered(manyLoanData, excessDeficitIndex + 1);
    builder.addAutoRenderedRows(loanCalculators);
  } else if (noiAnalysisType === NOIAnalysisType.DSCR) {
    const dscrCalculatorData: DSCRCalculatorData = {
      noiRow: 10,
      debtServiceRows: [13],
      startingColumn: "B",
      lenderId,
      propertyColumnCount: years.length,
      rollupOnly: false,
    };
    const dscrCalculator = new DSCRCalculatorRendered(dscrCalculatorData, builder.body.length + 1);
    builder.addAutoRenderedRows(dscrCalculator);
  }
  return builder;
}
