import { DocumentUpload, DocumentUploadStatus } from "@interfold-ai/db";
import { createSelector } from "@reduxjs/toolkit";
import { loanStateSelector } from "./app.selectors";
import { FileReference } from "src/pages/api/documents/ocr/common";
import { DocumentDetails } from "src/models/DocumentDetails";
import { ExtractableDocumentType } from "@interfold-ai/shared/enums/ExtractableDocumentType";
import { AssetCheckboxProps } from "src/redux/reducers/types";
import { EntityHydrated } from "src/models/entity";
import { slugify } from "src/utils/helpers";
import {
  getDocumentPeriodTextWithType,
  getDocumentRequestKey,
} from "@interfold-ai/shared/models/DocumentRequest";
import _ from "lodash";
import { spreadErrorsSelector } from "./ocr.selector";

export const pipelineLoansSelector = createSelector(
  [loanStateSelector],
  (loansState) => loansState.entities.list,
);

export const investmentTypeSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.entities.detail.investment_type,
);

export const currentInvesmentDetailDocumentClassificationSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.entities.detail.documentClassificationStatus || [],
);

export const validStatusesForSpreading: DocumentUploadStatus[] = [
  DocumentUploadStatus.COLLECTED,
  DocumentUploadStatus.VERIFIED,
];

export const managerEmployerIdSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.entities.detail.manager.employerId,
);

export const pipelineSearchParamsSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.searchParams,
);

export const loanDetailSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.entities.detail,
);

export const pipelineLoadingSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.entities.isLoading,
);

export const pipelineLoadedSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.entities.isLoaded,
);

export const annualReviewLoadingSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.entities.annualReviewIsLoading,
);

export const annualReviewLoadedSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.entities.annualReviewIsLoaded,
);

export const currentAnnualReviewSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.currentAnnualReviewId,
);

export const currentLoanSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.currentLoanId,
);

export const loanStartedSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.loanStarted,
);

export const pipelineErrorSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.error,
);

export const spreadableDocsSelector = createSelector([loanStateSelector], (loanState) => {
  const entityFileReferences = loanState.entities.detail.entities.reduce(
    (acc: FileReference[], entity: EntityHydrated) => {
      const documentRequests = entity.documentRequests ?? [];
      const { id: entityId } = entity;

      const fileReferences = documentRequests.map((request) => {
        const { attributes, id: documentRequestId } = request;
        const { year } = attributes ?? {};
        if (!year) {
          return [];
        }
        const documentType = request.type;

        return request.documentUpload.map((upload) => {
          const spreadsDocumentType = documentType.name;
          const { id: documentUploadId } = upload;
          const extractors: ExtractableDocumentType[] = [spreadsDocumentType.toString()]
            .map(slugify)
            .map((slug) => slug.toUpperCase() as ExtractableDocumentType);
          const refs: FileReference[] = extractors.map((extractorType) => ({
            documentRequestId,
            documentUploadId,
            fileYear: year,
            extractorType,
            documentType,
            entityName: entity.asCompany?.name || entity.asIndividual?.name || "",
            entityId,
          }));
          return refs;
        });
      });
      acc.push(...fileReferences.flat(2));
      return acc;
    },
    [] as FileReference[],
  );

  return entityFileReferences;
});

export const allDocumentRequestsSelector = createSelector([loanStateSelector], (loanState) => {
  return loanState.entities.detail.allDocumentRequests;
});

export const initialSpreadCheckboxState = createSelector(
  [loanStateSelector, allDocumentRequestsSelector, spreadErrorsSelector],
  (loanState, allDocumentRequests, spreadErrors) => {
    const initialState = loanState.entities.detail.entities.reduce(
      (acc: Record<string, AssetCheckboxProps[]>, entity: EntityHydrated) => {
        const documentRequests = allDocumentRequests?.[entity.id] ?? [];
        const entityName = entity.asCompany?.name || entity.asIndividual?.name || "";
        const mapped = documentRequests
          .map((docRequest) => {
            const {
              type: { name: typeName },
            } = docRequest;

            const lastUpload = docRequest.DocumentUpload.toSorted((a, b) => b.id - a.id)[0];
            if (!lastUpload.rawUploadUrl) {
              return [];
            }

            const { id: documentUploadId } = lastUpload;
            // @ts-ignore
            const label = getDocumentPeriodTextWithType(docRequest);
            const prop = {
              checked: !Boolean(spreadErrors[documentUploadId]),
              documentUploadId,
              disabled: Boolean(spreadErrors[documentUploadId]),
              entityName,
              // @ts-ignore
              key: getDocumentRequestKey(docRequest),
              label,
              documentTypeName: typeName,
            };
            return [prop];
          })
          .flat()
          .sort((a, b) => a.key.localeCompare(b.key));

        acc[entityName] = mapped;
        return acc;
      },
      {} as Record<string, AssetCheckboxProps[]>,
    );

    return initialState;
  },
);

export const allDocumentUploadIdsSelector = createSelector([loanStateSelector], (loanState) => {
  const loanDetails = loanState.entities.detail;
  return (
    loanDetails.documentRequests
      ?.map((docDetails: DocumentDetails) =>
        docDetails?.docStatus?.map((docStatus) =>
          docStatus.frequencies
            .filter((freq) => validStatusesForSpreading.includes(freq.status))
            .map((freq) => freq.documentUploadId),
        ),
      )
      .flat(2) ?? []
  );
});

export const allEntitiesSelector = createSelector([loanStateSelector], (loanState) =>
  loanState.entities.detail.entities.map((entity: EntityHydrated) => {
    const label = entity.asCompany ? entity.asCompany.name : entity.asIndividual?.name || "";
    return { id: entity.id, label, value: entity.id, entityId: entity.id };
  }),
);

export const entityNameToEntityIdSelector = createSelector([loanStateSelector], (loanState) =>
  loanState.entities.detail.entities.reduce(
    (acc, entity: EntityHydrated) => {
      const label = entity.asCompany ? entity.asCompany.name : entity.asIndividual?.name || "";
      acc[label] = entity.id;
      return acc;
    },
    {} as Record<string, number>,
  ),
);

export const lenderEmployeeDraftLoansSelector = createSelector(
  [loanStateSelector],
  (loanState) => loanState.draftLoans,
);

export const allUploadedDocumentsSelector = createSelector([loanStateSelector], (loanState) => {
  const loanDetails = loanState.entities.detail;
  return Object.values(loanDetails?.allDocumentRequests ?? {}).reduce(
    (acc: DocumentUpload[], entityDocumentRequests) => {
      return [
        ...acc,
        ...entityDocumentRequests.reduce((innerAcc: DocumentUpload[], documentRequest) => {
          const uploads =
            _.get(documentRequest, "DocumentUpload") || _.get(documentRequest, "documentUpload");

          if (Array.isArray(uploads)) {
            const collectedUploads = uploads.filter(
              (upload) => upload.status === DocumentUploadStatus.COLLECTED,
            );
            return [...innerAcc, ...collectedUploads];
          }

          return innerAcc;
        }, []),
      ];
    },
    [],
  );
});
