import { AutoRenderedSheetBuilder } from "./AutoRenderedSheetBuilder";
import { AutoRendered } from "../AutoRendered";
import { RawCellContent } from "hyperformula";
import { RowWithType } from "../RowWithType";
import { NOIGroupedAsset } from "./NOIGroupedAsset";
import { SupportedLenderId } from "@interfold-ai/shared/models/SpreadsConfig";
import { NOIAnalysisType } from "./Workflows/RenderedNOIAnalysis";
import { ManyLoanCalculatorsRendered } from "./LoanCalculatorRendered";
import { DSCRCalculatorData, DSCRCalculatorRendered } from "./DSCRCalculatorRendered";
import { ManyLoanCalculatorsData } from "./LoanCalculatorRendered";
import { spreadConfig } from "@interfold-ai/shared/spreads-config";

export const NOIGroupedAssetsHeadings = {
  Source: "Source",
  Entity: "Entity",
  Property: "Property",
  Year: "Year",
  GrossRents: "Gross Rents",
  TotalExpenses: "Total Expenses",
  NetIncome: "Net Income",
  Interest: "Interest",
  Depreciation: "Depreciation",
  Amortization: "Amortization",
  NetOperatingIncome: "Net Operating Income (NOI)",
  DebtServiceIndividual: "Debt Service (Individual)",
  DebtServiceShared: "Debt Service (Shared)",
  ExcessCashFlow: "Excess Cash Flow",
  DebtServiceCoverageRatio: "Debt Service Coverage Ratio",
  TargetDSCR: "Target DSCR",
};

function buildNOIGroupedAssetsTable(
  data: NOIGroupedAsset,
  startingColumn: string,
  startingRow: number,
) {
  const builder = new AutoRenderedSheetBuilder(
    data,
    NOIGroupedAssetsHeadings,
    startingRow,
    startingColumn,
  );

  builder
    .addRow(({ data, labels }) => [labels.Source, `${data.year} ${data.source}`], "text", "normal")
    .addRow(({ data, labels }) => [labels.Entity, data.entityName ?? ""], "text")
    .addRow(({ data, labels }) => [labels.Property, data.propertyName ?? ""], "text")
    .addRow(({ data, labels }) => [labels.GrossRents, data.grossRents ?? 0], "number")
    .addRow(({ data, labels }) => [labels.TotalExpenses, data.totalExpenses], "number")
    .addRow(({ data, labels }) => [labels.Interest, data.interest], "number")
    .addRow(({ data, labels }) => [labels.Depreciation, data.depreciation], "number")
    .addRow(({ data, labels }) => [labels.Amortization, data.amortization.statementValue], "number")
    .addRow(
      ({ labels, columnReference }) => [
        labels.NetOperatingIncome,
        `=${columnReference(labels.GrossRents)}-${columnReference(labels.TotalExpenses)}+${columnReference(labels.Interest)}+${columnReference(labels.Depreciation)}+${columnReference(labels.Amortization)}`,
      ],
      "number",
    );

  return builder;
}

export class NOIGroupedAssetsTable extends AutoRendered<NOIGroupedAsset[]> {
  private defaultTargetDSCR: number;

  asColumns(): RawCellContent[][] {
    if (this.underlying.length === 0) {
      return [];
    }
    const startingRow = 0;
    const data = this.underlying.sort((a, b) => Number(a.year) - Number(b.year));
    const body: RawCellContent[][] = [];

    const renderedForms = data.map((form, i) => {
      const startingColumn = this.getColumnLetter(i + 1);
      return buildNOIGroupedAssetsTable(form, startingColumn, startingRow);
    });

    const allLabels = new Set<string>();
    renderedForms.forEach((rendered) => {
      rendered.body.forEach((row) => {
        allLabels.add(row[0] as string);
      });
    });
    const labels = Array.from(allLabels);

    labels.forEach((label, i) => {
      const rowIndex = i + 1;
      const row: RowWithType = [label];
      if (
        label === NOIGroupedAssetsHeadings.Source ||
        label === NOIGroupedAssetsHeadings.Entity ||
        label === ""
      ) {
        row.push("");
      } else if (label === NOIGroupedAssetsHeadings.Property) {
        row.push("Combined All Properties");
      } else {
        row.push(
          `=SUM(${this.getColumnLetter(1)}${rowIndex}:${this.getColumnLetter(data.length)}${rowIndex})`,
        );
      }
      renderedForms.forEach((rendered) => {
        const matchingRow = rendered.body.find((r) => r[0] === label);
        if (matchingRow) {
          row.push(matchingRow[1]);
          row.rowDataType = matchingRow.rowDataType;
        } else {
          row.push(null);
        }
      });
      body.push(row);
    });

    // now for shared totals rows
    const defaultTargetDSCR = this.defaultTargetDSCR;
    const analysisType = this.analysisType;
    const noiRowIndex = body.length;
    const debtServiceIndividualRowIndex = body.length + 1;
    const debtServiceSharedRowIndex = body.length + 2;
    const col = this.getColumnLetter.bind(this);
    const addColSpan = (cellIdentifier: string, colSpan: number) => {
      this.colSpans[cellIdentifier] = colSpan;
    };
    body.push(debtServiceRow());
    body.push(debtServiceSharedRow());
    body.push(excessCashFlowRow());

    const excessDeficitIndex = body.length;
    if (this.analysisType === NOIAnalysisType.DEAL_SIZING) {
      body.push(targetDscrRow());

      const excessDeficitRefs: string[] = data.map((_, i) => `${col(i)}${excessDeficitIndex}`);
      excessDeficitRefs.push("");
      const newDebtRefs: string[] = data.map((_, i) => `${col(i)}11`);
      const manyLoanData: ManyLoanCalculatorsData = {
        excessCashflows: excessDeficitRefs,
        lenderId: this.lenderId,
        newDebt: newDebtRefs,
      };
      const loanCalculators = new ManyLoanCalculatorsRendered(
        manyLoanData,
        excessDeficitIndex + 2,
        true,
      );

      return [...body, ...loanCalculators.asColumns()];
    }

    const dscrCalculatorData: DSCRCalculatorData = {
      noiRow: noiRowIndex,
      debtServiceRows: [debtServiceIndividualRowIndex, debtServiceSharedRowIndex],
      startingColumn: "B",
      lenderId: this.lenderId,
      propertyColumnCount: data.length,
      rollupOnly: true,
    };
    const dscrCalculator = new DSCRCalculatorRendered(dscrCalculatorData, excessDeficitIndex + 1);

    return [...body, ...dscrCalculator.asColumns()];

    function debtServiceRow() {
      const currentRow = body.length + 1;
      const debtServiceRow: RowWithType = [
        NOIGroupedAssetsHeadings.DebtServiceIndividual,
        `=SUM(C${currentRow}:${col(data.length)}${currentRow})`,
        ...Array(data.length).fill(0),
      ];
      debtServiceRow.rowDataType = "number";
      return debtServiceRow;
    }

    function debtServiceSharedRow() {
      const currentRow = body.length + 1;
      const targetDSCRRow = currentRow + 2;
      addColSpan(`C${currentRow}`, data.length);

      if (analysisType === NOIAnalysisType.DEAL_SIZING) {
        // NOI / Target DSCR - Existing Debt
        const debtServiceSharedRow: RowWithType = [
          NOIGroupedAssetsHeadings.DebtServiceShared,
          `=B${noiRowIndex} / B${targetDSCRRow} - B${debtServiceIndividualRowIndex}`,
          ...Array(data.length).fill(" "),
        ];
        debtServiceSharedRow.rowDataType = "number";
        return debtServiceSharedRow;
      } else {
        const debtServiceSharedRow: RowWithType = [
          NOIGroupedAssetsHeadings.DebtServiceShared,
          `=C${currentRow}`,
          0, // the value is here and the other cells reference it
          ...Array(data.length - 1).fill(`=C${currentRow}`),
        ];
        debtServiceSharedRow.rowDataType = "number";
        return debtServiceSharedRow;
      }
    }

    function excessCashFlowRow() {
      const excessCashFlowRow: RowWithType = [
        NOIGroupedAssetsHeadings.ExcessCashFlow,
        `=B${noiRowIndex} - B${debtServiceIndividualRowIndex} - B${debtServiceSharedRowIndex}`,
        ...Array(data.length).fill(" "),
      ];
      excessCashFlowRow.rowDataType = "number";
      return excessCashFlowRow;
    }

    function targetDscrRow() {
      const targetDscrRow: RowWithType = [
        NOIGroupedAssetsHeadings.TargetDSCR,
        defaultTargetDSCR, // could get from lender's config
        ...Array(data.length).fill(" "),
      ];
      targetDscrRow.rowDataType = "number";
      return targetDscrRow;
    }
  }

  private getColumnLetter(offset: number): string {
    // +1 because we start with "A" for the year column
    // +1 because Excel is 1-indexed but our offsets are 0-indexed
    return this.colNumberToExcelCol(offset + 2);
  }

  get highlightedRowLabels(): string[] {
    return [
      NOIGroupedAssetsHeadings.NetOperatingIncome,
      NOIGroupedAssetsHeadings.DebtServiceIndividual,
      NOIGroupedAssetsHeadings.DebtServiceShared,
      NOIGroupedAssetsHeadings.ExcessCashFlow,
      NOIGroupedAssetsHeadings.DebtServiceCoverageRatio,
      NOIGroupedAssetsHeadings.TargetDSCR,
    ];
  }

  percentageRowLabels: string[] = [];
  metadataRowLabels: string[] = [
    NOIGroupedAssetsHeadings.Source,
    NOIGroupedAssetsHeadings.Entity,
    NOIGroupedAssetsHeadings.Property,
  ];

  constructor(
    data: NOIGroupedAsset[],
    private lenderId: SupportedLenderId,
    private analysisType: NOIAnalysisType,
  ) {
    super(data);
    this.defaultTargetDSCR = spreadConfig.lenderSettings[this.lenderId].defaultDSCR;
  }
}
