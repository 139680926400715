import { stringSimilarity } from "string-similarity-js";

export interface NameMatchOptions {
  firstNameThreshold?: number; // Similarity threshold for first name (0 to 1)
  lastNameThreshold?: number; // Similarity threshold for last name (0 to 1)
}

export function compareNames(
  nameA: string,
  nameB: string,
  options: NameMatchOptions = {},
): boolean {
  const { firstNameThreshold = 0.8, lastNameThreshold = 0.8 } = options;
  const normalize = (name: string) =>
    name
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9\s]/g, ""); // Remove all non-letter characters except spaces
  const tokenize = (name: string) => normalize(name).split(/\s+/);
  const tokensA = tokenize(nameA);
  const tokensB = tokenize(nameB);

  if (tokensA.length < 2 || tokensB.length < 2) {
    return false; // Require at least First + Last name
  }

  // First Name Comparison
  const firstNameSim = stringSimilarity(tokensA[0], tokensB[0]);
  if (firstNameSim < firstNameThreshold) {
    return false;
  }

  // Last Name Comparison
  const lastNameSim = stringSimilarity(tokensA[tokensA.length - 1], tokensB[tokensB.length - 1]);
  if (lastNameSim < lastNameThreshold) {
    return false;
  }

  // Middle Name/Initial Comparison
  const middleA = tokensA.length === 3 ? tokensA[1] : null;
  const middleB = tokensB.length === 3 ? tokensB[1] : null;

  if (middleA && middleB) {
    if (middleA !== middleB) {
      return false;
    } // Both present & don't match => fail
  }
  // If one lacks middle, allow match

  return true;
}
