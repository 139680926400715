import { Form1065 } from "@interfold-ai/shared/models/tax/Form1065";
import { AutoRenderedSheetBuilder } from "../AutoRenderedSheetBuilder";

export const Form1065BalanceSheetRowHeadings = {
  Assets: "Assets",
  Cash: "Cash",
  AccountsReceivableNet: "Accounts and notes receivable (current), net",
  AccountsReceivableGross: "Accounts and notes receivable, gross amount",
  AllowanceForBadDebts: "Less: Allowance for bad debts",
  Inventory: "Inventory",
  MarketableSecurities: "Marketable securities",
  USGovernmentObligations: "U.S. government obligations",
  TaxExemptSecurities: "Tax-exempt securities",
  OtherCurrentAssets: "Other current assets",
  TotalCurrentAssets: "Total current assets",

  NotesReceivableNonCurrent: "Notes receivable (non-current)",
  LoansToPartners: "Loans to partners (or persons related to partners)",
  OtherInvestments: "Other investments",
  LandNet: "Land (net of any amortization)",
  BuildingsNet: "Buildings and other depreciable assets, net of depreciation",
  DepletableAssetsNet: "Depletable assets, net",
  IntangibleAssetsNet: "Intangible assets, net of depreciation",
  OtherAssets: "Other assets",
  TotalNonCurrentAssets: "Total non-current assets",

  TotalAssets: "Total assets",

  LiabilitiesAndEquity: "Liabilities and Shareholders' Equity",
  AccountsPayable: "Accounts payable",
  ShortTermDebt: "Short term debt and current maturities of long term debt",
  OtherCurrentLiabilities: "Other current liabilities",
  TotalCurrentLiabilities: "Total current liabilities",

  AllNonrecourseLoans: "All nonrecourse loans",
  LongTermDebt: "Long term debt",
  LoansFromPartners: "Loans from partners (or persons related to partners)",
  OtherNonCurrentLiabilities: "Other non-current liabilities",
  TotalNonCurrentLiabilities: "Total non-current liabilities",

  TotalShareholdersEquity: "Total shareholders' equity",
  PartnersCapital: "Partners' capital accounts",
  TotalLiabilitiesAndCapital: "Total liabilities and capital",

  TotalLiabilities: "Total liabilities",
  TotalLiabilitiesAndShareholdersEquity: "Total liabilities and shareholders' equity",
};

export function buildForm1065BalanceSheet(
  data: Form1065,
  startingColumn: string,
  startingRow: number,
) {
  const assets = (data: Form1065) => data.schedules?.scheduleL?.balanceSheetsPerBooks?.assets;
  const liabilities = (data: Form1065) =>
    data.schedules?.scheduleL?.balanceSheetsPerBooks?.liabilitiesAndEquity;

  validateBalanceSheet();

  return new AutoRenderedSheetBuilder(
    data,
    Form1065BalanceSheetRowHeadings,
    startingRow,
    startingColumn,
  )
    .addRow(({ labels }) => [labels.Assets, ""])
    .addRow(({ data, labels }) => [labels.Cash, assets(data)?.cash?.endOfTaxYear ?? 0])
    .addRow(({ data, labels }) => [
      labels.AccountsReceivableNet,
      (assets(data)?.tradeNotesAndAccountsReceivable?.endOfTaxYear ?? 0) -
        (assets(data)?.lessAllowanceForBadDebts?.endOfTaxYear ?? 0),
    ])
    .addRow(({ data, labels }) => [labels.Inventory, assets(data)?.inventories?.endOfTaxYear ?? 0])
    .addRow(({ data, labels }) => [
      labels.MarketableSecurities,
      (assets(data)?.usGovernmentObligations?.endOfTaxYear ?? 0) +
        (assets(data)?.taxExemptSecurities?.endOfTaxYear ?? 0),
    ])
    .addRow(({ data, labels }) => [
      labels.OtherCurrentAssets,
      assets(data)?.otherCurrentAssets?.endOfTaxYear ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalCurrentAssets,
      `=${columnReference(labels.Cash)} + ${columnReference(labels.AccountsReceivableNet)} + ${columnReference(labels.Inventory)} + ${columnReference(labels.MarketableSecurities)} + ${columnReference(labels.OtherCurrentAssets)}`,
    ])

    .addRow(({ data, labels }) => [
      labels.NotesReceivableNonCurrent,
      assets(data)?.mortgageAndRealEstateLoans?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.LoansToPartners,
      assets(data)?.loansToShareholdersOrPartners?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.OtherInvestments,
      assets(data)?.otherInvestments?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.LandNet,
      assets(data)?.landNetOfAnyAmortization?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.BuildingsNet,
      assets(data)?.buildingsAndOtherDepreciableAssetsNetOfAccumulatedDepreciation?.endOfTaxYear ??
        0,
    ])
    .addRow(({ data, labels }) => [
      labels.DepletableAssetsNet,
      assets(data)?.depletableAssetsNetOfAccumulatedDepletion?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.IntangibleAssetsNet,
      assets(data)?.intangibleAssetsNetOfAccumulatedAmortization?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.OtherAssets,
      assets(data)?.otherAssets?.endOfTaxYear ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalNonCurrentAssets,
      `=${columnReference(labels.NotesReceivableNonCurrent)} + ${columnReference(labels.LoansToPartners)} + ${columnReference(labels.OtherInvestments)} + ${columnReference(labels.LandNet)} + ${columnReference(labels.BuildingsNet)} + ${columnReference(labels.DepletableAssetsNet)} + ${columnReference(labels.IntangibleAssetsNet)} + ${columnReference(labels.OtherAssets)}`,
    ])

    .addRow(({ labels, columnReference }) => [
      labels.TotalAssets,
      `=${columnReference(labels.TotalCurrentAssets)} + ${columnReference(labels.TotalNonCurrentAssets)}`,
    ])

    .addRow()
    .addRow(({ labels }) => [labels.LiabilitiesAndEquity, ""])
    .addRow(({ data, labels }) => [
      labels.AccountsPayable,
      liabilities(data)?.accountsPayable?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.ShortTermDebt,
      liabilities(data)?.mortgagesNotesAndBondsPayableLessThanOneYear?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.OtherCurrentLiabilities,
      liabilities(data)?.otherCurrentLiabilities?.endOfTaxYear ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalCurrentLiabilities,
      `=${columnReference(labels.AccountsPayable)} + ${columnReference(labels.ShortTermDebt)} + ${columnReference(labels.OtherCurrentLiabilities)}`,
    ])

    .addRow(({ data, labels }) => [
      labels.AllNonrecourseLoans,
      liabilities(data)?.allNonRecourseLoans?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.LongTermDebt,
      liabilities(data)?.mortgagesNotesAndBondsPayableMoreThanOneYear?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.LoansFromPartners,
      liabilities(data)?.loansFromShareholdersOrPartners?.endOfTaxYear ?? 0,
    ])
    .addRow(({ data, labels }) => [
      labels.OtherNonCurrentLiabilities,
      liabilities(data)?.otherLiabilities?.endOfTaxYear ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalNonCurrentLiabilities,
      `=${columnReference(labels.AllNonrecourseLoans)} + ${columnReference(labels.LongTermDebt)} + ${columnReference(labels.LoansFromPartners)} + ${columnReference(labels.OtherNonCurrentLiabilities)}`,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalLiabilities,
      `=${columnReference(labels.TotalCurrentLiabilities)} + ${columnReference(labels.TotalNonCurrentLiabilities)}`,
    ])

    .addRow(({ data, labels }) => [
      labels.PartnersCapital,
      liabilities(data)?.capitalStockOrPartnersCapital?.endOfTaxYear ?? 0,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalShareholdersEquity,
      `=${columnReference(labels.PartnersCapital)}`,
    ])
    .addRow(({ labels, columnReference }) => [
      labels.TotalLiabilitiesAndShareholdersEquity,
      `=${columnReference(labels.TotalLiabilities)} + ${columnReference(labels.TotalShareholdersEquity)}`,
    ]);

  function validateBalanceSheet() {
    const totalAssets = assets(data)?.totalAssets?.endOfTaxYear ?? 0;
    const totalCurrentAssets =
      (assets(data)?.cash?.endOfTaxYear ?? 0) +
      (assets(data)?.tradeNotesAndAccountsReceivable?.endOfTaxYear ?? 0) -
      (assets(data)?.lessAllowanceForBadDebts?.endOfTaxYear ?? 0) +
      (assets(data)?.inventories?.endOfTaxYear ?? 0) +
      (assets(data)?.usGovernmentObligations?.endOfTaxYear ?? 0) +
      (assets(data)?.taxExemptSecurities?.endOfTaxYear ?? 0) +
      (assets(data)?.otherCurrentAssets?.endOfTaxYear ?? 0);
    const totalNonCurrentAssets =
      (assets(data)?.mortgageAndRealEstateLoans?.endOfTaxYear ?? 0) +
      (assets(data)?.loansToShareholdersOrPartners?.endOfTaxYear ?? 0) +
      (assets(data)?.otherInvestments?.endOfTaxYear ?? 0) +
      (assets(data)?.landNetOfAnyAmortization?.endOfTaxYear ?? 0) +
      (assets(data)?.buildingsAndOtherDepreciableAssetsNetOfAccumulatedDepreciation?.endOfTaxYear ??
        0) +
      (assets(data)?.depletableAssetsNetOfAccumulatedDepletion?.endOfTaxYear ?? 0) +
      (assets(data)?.intangibleAssetsNetOfAccumulatedAmortization?.endOfTaxYear ?? 0) +
      (assets(data)?.otherAssets?.endOfTaxYear ?? 0);
    const totalAssetsMatch = totalAssets === totalCurrentAssets + totalNonCurrentAssets;

    const totalLiabilities =
      (liabilities(data)?.accountsPayable?.endOfTaxYear ?? 0) +
      (liabilities(data)?.mortgagesNotesAndBondsPayableLessThanOneYear?.endOfTaxYear ?? 0) +
      (liabilities(data)?.otherCurrentLiabilities?.endOfTaxYear ?? 0) + // total current liabilities
      (liabilities(data)?.allNonRecourseLoans?.endOfTaxYear ?? 0) +
      (liabilities(data)?.mortgagesNotesAndBondsPayableMoreThanOneYear?.endOfTaxYear ?? 0) +
      (liabilities(data)?.loansFromShareholdersOrPartners?.endOfTaxYear ?? 0) +
      (liabilities(data)?.otherLiabilities?.endOfTaxYear ?? 0); // total non-current liabilities

    const totalShareholdersEquity =
      liabilities(data)?.capitalStockOrPartnersCapital?.endOfTaxYear ?? 0;
    const totalLiabilitiesAndShareholdersEquity = totalLiabilities + totalShareholdersEquity;
    const totalsMatch =
      totalCurrentAssets + totalNonCurrentAssets === totalLiabilitiesAndShareholdersEquity;

    if (!totalAssetsMatch) {
      console.error(
        `Total assets and total current assets and total non-current assets do not match. Total assets: ${totalAssets}, total current assets: ${totalCurrentAssets}, total non-current assets: ${totalNonCurrentAssets}`,
      );
    }
    if (!totalsMatch) {
      console.error(
        `Total assets and total liabilities and shareholders' equity do not match. Total assets: ${totalAssets}, total liabilities and shareholders' equity: ${totalLiabilitiesAndShareholdersEquity}`,
      );
    }
  }
}
