import { Form1040 } from "@interfold-ai/shared/models/tax/Form1040";
import { Form1065 } from "@interfold-ai/shared/models/tax/Form1065";
import { Form1120S } from "@interfold-ai/shared/models/tax/Form1120s";
import { JointlyOwnedPropertiesFromMultipleForms } from "../../../page-components/investment-detail/tabs/SpreadsTab/prepareSpreadCalculationInputs";
import { TaxFormDataByYear } from "../../../redux/reducers/types";
import { NOIAnalysisTabTypeEnum } from "../helpers";
import { colNumberToExcelCol, excelColToColNumber } from "../../RenderedDoc";
import { formatNoiAnalysisTabName } from "./formatNoiAnalysisTabName";
import { TaxFormYear } from "@interfold-ai/shared/models/tax/common";
/**
 * For each year, include a list of tab names
 * and the column to use for calculating each
 * row for the global total.
 */
export interface GlobalTaxReturnGroups {
  [year: string]: {
    tabName: string;
    column: string;
  }[];
}

export const groupTaxReturnsForGlobalTotals = (
  personalTaxReturns: TaxFormDataByYear<Form1040>,
  businessTaxReturns: TaxFormDataByYear<Form1065 | Form1120S>,
  jointlyOwnedProperties: TaxFormDataByYear<JointlyOwnedPropertiesFromMultipleForms> | undefined,
): GlobalTaxReturnGroups => {
  const years = [
    ...new Set([
      ...Object.keys(personalTaxReturns),
      ...Object.keys(businessTaxReturns),
      ...Object.keys(jointlyOwnedProperties ?? {}),
    ]),
  ].sort();
  const globalTaxReturnGroups: GlobalTaxReturnGroups = {};
  years.forEach((year) => {
    globalTaxReturnGroups[year] = [];
  });
  const ptrEntityByYear: Record<string, TaxFormYear[]> = Object.entries(personalTaxReturns).reduce(
    (acc, [year, forms]) => {
      forms.forEach((form) => {
        acc[form.entityName] = [...(acc[form.entityName] || []), year as TaxFormYear];
      });
      return acc;
    },
    {} as Record<string, TaxFormYear[]>,
  );
  const btrEntityByYear: Record<string, TaxFormYear[]> = Object.entries(businessTaxReturns).reduce(
    (acc, [year, forms]) => {
      forms.forEach((form) => {
        acc[form.entityName] = [...(acc[form.entityName] || []), year as TaxFormYear];
      });
      return acc;
    },
    {} as Record<string, TaxFormYear[]>,
  );
  const jotEntityByYear: Record<string, TaxFormYear[]> = Object.entries(
    jointlyOwnedProperties ?? {},
  ).reduce(
    (acc, [year, forms]) => {
      forms.forEach((form) => {
        acc[form.entityName] = [...(acc[form.entityName] || []), year as TaxFormYear];
      });
      return acc;
    },
    {} as Record<string, TaxFormYear[]>,
  );
  for (const ptr of Object.values(personalTaxReturns).flat()) {
    const ptrYear = ptr.year;
    const yearList = ptrEntityByYear[ptr.entityName];
    const ptrIndex = yearList.indexOf(ptrYear);
    const tabName = formatNoiAnalysisTabName(
      NOIAnalysisTabTypeEnum.PERSONAL_TAX_RETURN,
      `Total - ${ptr.entityName}`,
      ptrYear,
    );
    const column = colNumberToExcelCol(excelColToColNumber("B") + ptrIndex);
    globalTaxReturnGroups[ptrYear].push({
      tabName,
      column,
    });
  }
  for (const btr of Object.values(businessTaxReturns).flat()) {
    const btrYear = btr.year;
    const yearList = btrEntityByYear[btr.entityName];
    const btrIndex = yearList.indexOf(btrYear);
    const tabName = formatNoiAnalysisTabName(
      NOIAnalysisTabTypeEnum.BUSINESS_TAX_RETURN,
      `Total - ${btr.entityName}`,
      btrYear,
    );
    const column = colNumberToExcelCol(excelColToColNumber("B") + btrIndex);
    globalTaxReturnGroups[btrYear].push({
      tabName,
      column,
    });
  }
  for (const jot of Object.values(jointlyOwnedProperties ?? {}).flat()) {
    const jotYear = jot.year;
    const yearList = jotEntityByYear[jot.entityName];
    const jotIndex = yearList.indexOf(jotYear);
    const tabName = formatNoiAnalysisTabName(
      NOIAnalysisTabTypeEnum.JOINTLY_OWNED_PROPERTIES,
      `Total`,
      jotYear,
    );
    const column = colNumberToExcelCol(excelColToColNumber("B") + jotIndex);
    globalTaxReturnGroups[jotYear].push({
      tabName,
      column,
    });
  }
  return globalTaxReturnGroups;
};
