import { TabName } from "src/redux/reducers/types";
import { NOIAnalysisTabTypeEnum, formatNameForSubject, formatNameForRentRoll } from "../helpers";
import { sanitizeTabName } from "../utils";

export function formatNoiAnalysisTabName(
  tabType: NOIAnalysisTabTypeEnum,
  descriptiveName: string,
  year: string,
): TabName {
  let tabName = "";
  switch (tabType) {
    case NOIAnalysisTabTypeEnum.PERSONAL_TAX_RETURN:
      tabName = `PTR - ${descriptiveName || year}` as TabName;
      break;
    case NOIAnalysisTabTypeEnum.BUSINESS_TAX_RETURN:
      tabName = `BTR - ${descriptiveName || year}` as TabName;
      break;
    case NOIAnalysisTabTypeEnum.CUSTOM_GROUPING:
      tabName = `${descriptiveName} - ${year}` as TabName;
      break;
    case NOIAnalysisTabTypeEnum.JOINTLY_OWNED_PROPERTIES:
      tabName = `JO - ${descriptiveName || year}` as TabName;
      break;
    case NOIAnalysisTabTypeEnum.SUBJECT_ASSETS:
      tabName = formatNameForSubject(descriptiveName) as TabName;
      break;
    case NOIAnalysisTabTypeEnum.RENT_ROLL:
      tabName = formatNameForRentRoll(descriptiveName) as TabName;
      break;
    default:
      throw new Error(`Unknown tab type: ${tabType}`);
  }
  return sanitizeTabName(tabName) as TabName;
}
